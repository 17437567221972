import React, { useEffect, useState } from "react";
import "../utils/orderComponent.css"; // Ensure this CSS matches Laravel styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FaStar } from "react-icons/fa";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import Carousel from "react-bootstrap/Carousel";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "../features/counterSlice";

const myComments = [
  " I'm really impressed with the design of this product. It's sleek, modern, and exactly to my taste. ",
  "I have tried several similar products, but this one stands out as the best. I wouldn't hesitate to buy it again.",
  "The performance of this product exceeds my expectations. It's incredibly powerful and reliable.",
  "The performance of this product is outstanding. It consistently delivers excellent results.",
  "I have already purchased multiple items from this brand, and I am consistently impressed with the quality.",
  "This Product is Amazing.It is more than Peak.Just try one!",
  "Holy moly guys, This is Something that exceeds my expectations.",
  "To be Honest, I never believed that this kind of delivery product is not properly useful.But in real, this is abnormal",
  "Thanks guys. Your reviews make me try this one",
  "Huh this product is weired",
  "Some people will say, buying products online is not trusted. yeah, some of that might be true, but this product, is reliable.",
  "Services are good and delivery fees is comfortable.Thanks man",
  "You guys should try some of these.",
  "looking for something useful and trusted, yeah you found it just right here",
  "soo bad bro",
  "Thanks a lot man, you saved my damy.",
  "First Opnion, I thought it was just a fake like I have experienced before.But after I found out that this product is reliable and good reviews, I tried some.Thank you!",
  'I don"nt know what to say, but thank you',
  "reliable product, top reviews, comfortable delivery fees , great services, what anything else guys, just buy it",
  "This product exceeded my expectations in every way. The quality is outstanding, and it performs flawlessly. Highly recommend to anyone in need of this.",
  "I was pleasantly surprised by the build quality of this item. It works perfectly and has made my daily tasks much easier. Worth every penny.",
  "After using this product for a few weeks, I can confidently say it is one of the best purchases I've made this year. Fantastic value.",
  "I am extremely satisfied with this purchase. The product arrived on time and in perfect condition. It functions exactly as described and is very user-friendly.",
  "This is a high-quality item that delivers excellent performance. It has proven to be very reliable and durable. Would definitely purchase again.",
  "The product's design is sleek and modern. It fits well with my home decor and works like a charm. I am very pleased with it.",
  "I bought this product based on the positive reviews, and it did not disappoint. It works efficiently and is very easy to operate. Great buy.",
  "This product has made a significant difference in my daily routine. It is efficient, reliable, and worth every dollar spent. Highly recommend.",
  "I am very impressed with the performance of this product. It is well-made and does exactly what it claims to do. Excellent purchase.",
  "This item has exceeded my expectations in terms of quality and functionality. It was delivered promptly and has been working perfectly since day one.",
  "I highly recommend this product to anyone looking for a reliable and high-quality item. It performs exceptionally well and is very easy to use.",
  "The product arrived quickly and was well-packaged. It is of excellent quality and works as described. I am very satisfied with this purchase.",
  "I am very happy with this product. It is well-constructed, easy to use, and performs flawlessly. It has exceeded my expectations in every way.",
  "This product is worth every penny. It is made of high-quality materials and works perfectly. I would definitely buy it again.",
  "The product's performance is outstanding. It is very efficient and easy to use. I am extremely satisfied with this purchase and highly recommend it.",
  "I am very pleased with the quality of this product. It works exactly as described and has made my life much easier. Great value for money.",
  "This item is fantastic. It is well-designed, easy to use, and performs exceptionally well. I am very satisfied with my purchase.",
  "I was hesitant to buy this product at first, but I am so glad I did. It is of excellent quality and works perfectly. Highly recommend.",
  "The product exceeded my expectations in every way. It is very well-made and performs flawlessly. I am extremely happy with my purchase.",
  "I have been using this product for a while now, and it has been working perfectly. It is of high quality and very reliable. Great buy.",
  "This product is exactly what I was looking for. It is well-made, easy to use, and performs exceptionally well. Highly recommend to others.",
  "I am very impressed with the quality and performance of this product. It arrived quickly and has been working perfectly since I started using it.",
  "The product's design is sleek and functional. It fits perfectly in my home and works exactly as described. I am very satisfied with this purchase.",
  "I bought this product based on the positive reviews, and I am glad I did. It is of high quality and works flawlessly. Great value for money.",
  "This product has made a significant difference in my daily routine. It is efficient, reliable, and easy to use. Highly recommend to anyone.",
  "I am very pleased with the performance of this product. It is well-made and does exactly what it claims to do. Excellent purchase overall.",
  "The product exceeded my expectations in terms of quality and functionality. It was delivered promptly and has been working perfectly since I got it.",
  "I highly recommend this product to anyone looking for a reliable and high-quality item. It performs exceptionally well and is very easy to use.",
  "The product arrived quickly and was well-packaged. It is of excellent quality and works as described. I am very satisfied with this purchase.",
  "I am very happy with this product. It is well-constructed, easy to use, and performs flawlessly. It has exceeded my expectations in every way.",
  "This product is worth every penny. It is made of high-quality materials and works perfectly. I would definitely buy it again.",
  "The product's performance is outstanding. It is very efficient and easy to use. I am extremely satisfied with this purchase and highly recommend it.",
  "I am very pleased with the quality of this product. It works exactly as described and has made my life much easier. Great value for money.",
  "This item is fantastic. It is well-designed, easy to use, and performs exceptionally well. I am very satisfied with my purchase.",
  "I was hesitant to buy this product at first, but I am so glad I did. It is of excellent quality and works perfectly. Highly recommend.",
  "The product exceeded my expectations in every way. It is very well-made and performs flawlessly. I am extremely happy with my purchase.",
  "I have been using this product for a while now, and it has been working perfectly. It is of high quality and very reliable. Great buy.",
  "This product is exactly what I was looking for. It is well-made, easy to use, and performs exceptionally well. Highly recommend to others.",
  "I am very impressed with the quality and performance of this product. It arrived quickly and has been working perfectly since I started using it.",
  "The product's design is sleek and functional. It fits perfectly in my home and works exactly as described. I am very satisfied with this purchase.",
  "I bought this product based on the positive reviews, and I am glad I did. It is of high quality and works flawlessly. Great value for money.",
  "This product has made a significant difference in my daily routine. It is efficient, reliable, and easy to use. Highly recommend to anyone.",
  "I am very pleased with the performance of this product. It is well-made and does exactly what it claims to do. Excellent purchase overall.",
  "The product exceeded my expectations in terms of quality and functionality. It was delivered promptly and has been working perfectly since I got it.",
  "I highly recommend this product to anyone looking for a reliable and high-quality item. It performs exceptionally well and is very easy to use.",
  "The product arrived quickly and was well-packaged. It is of excellent quality and works as described. I am very satisfied with this purchase.",
  "I am very happy with this product. It is well-constructed, easy to use, and performs flawlessly. It has exceeded my expectations in every way.",
  "This product is worth every penny. It is made of high-quality materials and works perfectly. I would definitely buy it again.",
  "The product's performance is outstanding. It is very efficient and easy to use. I am extremely satisfied with this purchase and highly recommend it.",
  "I am very pleased with the quality of this product. It works exactly as described and has made my life much easier. Great value for money.",
  "This item is fantastic. It is well-designed, easy to use, and performs exceptionally well. I am very satisfied with my purchase.",
  "I was hesitant to buy this product at first, but I am so glad I did. It is of excellent quality and works perfectly. Highly recommend.",
  "The product exceeded my expectations in every way. It is very well-made and performs flawlessly. I am extremely happy with my purchase.",
  "I have been using this product for a while now, and it has been working perfectly. It is of high quality and very reliable. Great buy.",
  "This product is exactly what I was looking for. It is well-made, easy to use, and performs exceptionally well. Highly recommend to others.",
  "I am very impressed with the quality and performance of this product. It arrived quickly and has been working perfectly since I started using it.",
  "The product's design is sleek and functional. It fits perfectly in my home and works exactly as described. I am very satisfied with this purchase.",
  "I bought this product based on the positive reviews, and I am glad I did. It is of high quality and works flawlessly. Great value for money.",
  "This product has made a significant difference in my daily routine. It is efficient, reliable, and easy to use. Highly recommend to anyone.",
  "I am very pleased with the performance of this product. It is well-made and does exactly what it claims to do. Excellent purchase overall.",
  "The product exceeded my expectations in terms of quality and functionality. It was delivered promptly and has been working perfectly since I got it.",
  "I highly recommend this product to anyone looking for a reliable and high-quality item. It performs exceptionally well and is very easy to use.",
  "The product arrived quickly and was well-packaged. It is of excellent quality and works as described. I am very satisfied with this purchase.",
  "I am very happy with this product. It is well-constructed, easy to use, and performs flawlessly. It has exceeded my expectations in every way.",
];
const names = [
  "Dakota Alenoid",
  "Barry Thompson",
  "Clerk Dennis",
  "Isuka Hotayuri",
  "Ben Ben",
  "Brian Tovi",
  "Steven Hank",
  "Vivian",
  "Anna Jettisan",
  "Horace Halen",
  "Tony",
  "Jack Jack",
  "Kurona Kaiser",
  "Shawn",
  "Nick berkhard",
  "Helen Grey",
  "Isagi Yoichi",
  "Yuno Yamaji",
  "Ben Tenneson",
  "Jessican Bergara",
  "U Htun Aung",
  "Anna Kay",
  "James Smith",
  "Mary Johnson",
  "John Williams",
  "Patricia Brown",
  "Robert Jones",
  "Linda Garcia",
  "Michael Miller",
  "Barbara Davis",
  "William Rodriguez",
  "Elizabeth Martinez",
  "David Hernandez",
  "Jennifer Lopez",
  "Richard Wilson",
  "Maria Anderson",
  "Charles Thomas",
  "Susan Taylor",
  "Joseph Moore",
  "Margaret Jackson",
  "Thomas White",
  "Dorothy Harris",
  "Christopher Martin",
  "Sarah Thompson",
  "Daniel Garcia",
  "Karen Martinez",
  "Matthew Robinson",
  "Nancy Clark",
  "Anthony Lewis",
  "Betty Walker",
  "Donald Young",
  "Sandra Allen",
  "Mark King",
  "Ashley Wright",
  "Paul Scott",
  "Kimberly Torres",
  "Steven Nguyen",
  "Emily Hill",
  "Andrew Green",
  "Jessica Adams",
  "Kenneth Baker",
  "Amanda Gonzalez",
  "Joshua Nelson",
  "Sarah Carter",
  "Kevin Mitchell",
  "Laura Perez",
  "Brian Roberts",
  "Rebecca Turner",
  "George Phillips",
  "Michelle Campbell",
  "Edward Parker",
  "Sophia Evans",
  "Ronald Edwards",
  "Chloe Collins",
  "Timothy Stewart",
  "Megan Morris",
  "Jason Sanchez",
  "Hannah Rogers",
  "Jeffrey Reed",
  "Stephanie Cook",
  "Ryan Morgan",
  "Victoria Bell",
  "Jacob Murphy",
  "Diana Bailey",
  "Gary Rivera",
  "Alexis Cooper",
  "Nicholas Richardson",
  "Evelyn Cox",
  "Eric Howard",
  "Grace Ward",
  "Stephen Torres",
  "Isabella Peterson",
];
let countries = [
  "American",
  "Thailand",
  "Russia",
  "Taiwan",
  "Indonesia",
  "Japan",
  "Singapore",
];
let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let years = ["2020", "2021", "2022", "2023", "2024"];

const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];

const generateCommentHtml = (name, comment, month, country, day, year) => (
  <div className="my-4 eachComment">
    <div className="d-flex">
      <img
        src={`${process.env.PUBLIC_URL}/images/No need to edit/commentProfile.png`}
        style={{ width: "45px", height: "45px" }}
        alt=""
      />
      <div className="d-flex flex-column justify-content-center">
        <div className="ms-3">{name}</div>
      </div>
    </div>
    <div className="starContainer">
      <FontAwesomeIcon icon={faSolidStar} className=" text-warning" />
      <FontAwesomeIcon icon={faSolidStar} className=" text-warning" />
      <FontAwesomeIcon icon={faSolidStar} className=" text-warning" />
      <FontAwesomeIcon icon={faSolidStar} className=" text-warning" />
      <FontAwesomeIcon icon={faSolidStar} className=" text-warning" />
    </div>
    <h6 className="mt-1">Plug And Play</h6>
    <div style={{ color: "#939999" }}>
      Reviewed in the {country} on {month} {day}, {year}
    </div>
    <div style={{ color: "black" }}>{comment}</div>
  </div>
);

const OrderPage = () => {
  const navigate = useNavigate();
  const [isUnfold, setIsUnfold] = useState(false);
  const [firstStar, setFirstStar] = useState(false);
  const [secondStar, setSecondStar] = useState(false);
  const [thirdStar, setThirdStar] = useState(false);
  const [fourthStar, setFourthStar] = useState(false);
  const [fifthStar, setFifthStar] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFooterFirstSection, setFooterFirstSection] = useState(false);
  const [isFooterSEcondSection, setFooterSecondSection] = useState(false);
  const [comment, setComment] = useState("");

  const [comments, setComments] = useState([]);
  const dispatch = useDispatch();
  const myUserData = JSON.parse(getUser());
  const {
    data,
    time,
    commision,
    userData,
    orderCount,
    imgArray,
    firstBasicInfoArray,
    secondBasicInfoArray,
    productInfoArray,
    orderId,
  } = useSelector((store) => store.counter);
  const randomPost = data;
  // console.log(data);

  const insertRandomComments = (numberOfComments) => {
    const newComments = [];
    for (let i = 0; i < numberOfComments; i++) {
      const randomName = getRandomElement(names);
      const randomComment = getRandomElement(myComments);
      const randomCountry = getRandomElement(countries);
      const randomMonth = getRandomElement(months);
      const randomYear = getRandomElement(years);
      const randomDay = Math.floor(Math.random() * 30) + 1;
      newComments.push(
        generateCommentHtml(
          randomName,
          randomComment,
          randomMonth,
          randomCountry,
          randomDay,
          randomYear
        )
      );
    }
    setComments(newComments);
  };

  const getRandomComment = () => {
    const randomIndex = Math.floor(Math.random() * comments.length);
    setComment(myComments[randomIndex]);
  };
  const starClearFun = () => {
    setFirstStar(false);
    setSecondStar(false);
    setThirdStar(false);
    setFourthStar(false);
    setFifthStar(false);
  };

  const adminDomain = "https://admintesting.amzscoutmembers.com";
  const domain = "https://usertesting.amzscoutmembers.com";

  const token = getToken();
  // const orderCount = 42;
  const formatNumber = (number, decimals) => {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // For 12-hour format with AM/PM
    };
    return date.toLocaleString("en-US", options);
  };
  const [formattedDate, setFormattedDate] = useState("");
  const [randomNum, setRandomNum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [insufficient, setInsufficient] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [giftId, setGiftId] = useState(0);
  // modal Start
  const claimGift = async () => {
    try {
      const response = await axios.post(
        "https://admintesting.amzscoutmembers.com/api/getGift",
        {
          id: myUserData.id,
          giftId: giftId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        closeModal();
        navigate("/messages");
      }
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
    closeModal();
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // modal end

  const postOrder = async () => {
    if (comment == "") {
      return;
    }
    if (userData.wallet_balance < 0) {
      setInsufficient(true);
      setTimeout(() => {
        navigate("/deposit");
        setInsufficient(false);
      }, 2000);
      return;
    }
    if (!token) {
      navigate("/login");
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://admintesting.amzscoutmembers.com/api/postOrder",
        {
          userId: userData.id,
          commision:
            ((randomPost.price * randomPost.quantity_order) / 100) * commision,
          product_id: randomPost.id,
          plusAmount: randomPost.price * randomPost.quantity_order,
          percent: commision,
          id: orderId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        setIsLoading(false);
        if (
          response.data.status == "true" &&
          response.data.isConsecutive == "true"
        ) {
          dispatch(updateOrder(response.data));
          fetchData();
          return;
        }

        if (response.data.status == "true") {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
            navigate("/starting");
          }, 1234);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admintesting.amzscoutmembers.com/api/checkGift",
        {
          id: myUserData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      if (response.data.status == "true") {
        if (response.data.isGift == "true") {
          setModalOpen(true);
          setGiftId(response.data.gift.id);
          setAmount(response.data.gift.amount);
          return;
        } else {
          return;
        }
      }
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchData();
    const today = new Date();

    // Get the current date
    const date = today.getDate();

    // Get the current month (Note: Months are zero-based, so add 1)
    const monthIndex = today.getMonth();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[monthIndex];
    const formattedDateValue = date < 10 ? "0" + date : date;

    // Set the formatted date in the format "Month day-start - day-end"
    setFormattedDate(
      `${monthName} ${Number(formattedDateValue) + 3}-${
        Number(formattedDateValue) + 5
      }`
    );

    // Generate a random 7-digit number
    const randomNumValue = Math.floor(1000000 + Math.random() * 9000000);
    setRandomNum(randomNumValue);

    insertRandomComments(5);
  }, []);
  const { UserName } = useSelector((store) => store.counter);
  return (
    <div
      className="loginBody  position-relative   "
      style={{ overflow: "initial !important" }}
    >
      <style>
        {`    .loader {
      border: 4px solid #fff;; /* Black */
      border-top: 4px solid transparent; /* Transparent top border */
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 1s linear infinite;
      margin: auto;
    /* Adjust to center vertically */
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

`}
      </style>
      <div className="loginContainer position-relative top-0">
        <div
          onClick={() => {
            setFooterFirstSection(false);
            setFooterSecondSection(false);
          }}
          className="d-flex py-2 sticky-header position-fixed top-0"
        >
          <div
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            className="back-btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            <div className="ms-3">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                className="back-img"
                style={{ width: "25px", height: "25px" }}
                alt="back"
              />
            </div>
          </div>
          <h5
            style={{ fontSize: "1rem" }}
            className="text-white font-weight-bold text-center col-11 mt-1 title"
          >
            Order
          </h5>
        </div>
        <br />
        <br />

        <div
          onClick={() => {
            setFooterFirstSection(false);
            setFooterSecondSection(false);
          }}
          className="mt-2 mx-2 py-1 order-body"
          style={{ borderRadius: "20px" }}
        >
          <div className="mb-5 bg-white">
            <div className="float-start">
              <h5
                className="d-inline ms-3 brand-name"
                style={{ color: "#307083", fontWeight: "600" }}
              >
                Brand: {randomPost.brand_name}
                User: {UserName}
              </h5>
            </div>
            <div className="float-end d-flex">
              <div>{randomPost.score}</div>
              <div className="starContainer">
                <FaStar className=" text-warning" />
                <FaStar className=" text-warning" />
                <FaStar className=" text-warning" />
                <FaStar className=" text-warning" />
                {/* <FaStar className=" text-warning" /> */}
              </div>
              <div className="ms-2">261</div>
            </div>
          </div>

          <div className="ms-3">{randomPost.name}</div>
          <div className="w-100 d-flex justify-content-center">
            <img
              // src={`${process.env.PUBLIC_URL}/images/No need to edit/productOne.jpg`}
              src={`${adminDomain}/public/storage/${encodeURI(
                randomPost.cover_photo
              )}`}
              style={{ width: "400px" }}
              className="cover-photo"
              alt="cover"
            />
          </div>

          <div className="d-flex justify-content-center dots-container">
            <div className="d-flex mt-2">
              <div
                className=" rounded-circle p-1"
                style={{
                  backgroundColor: "black",
                  width: "6px",
                  border: "solid 0.4px",
                }}
              ></div>
              <div
                className=" ms-2 rounded-circle p-1"
                style={{ width: "6px", border: "solid 0.4px" }}
              ></div>
            </div>
          </div>

          <div
            className="w-100 mt-3 separator"
            style={{ border: "solid 1px rgb(135, 133, 133)" }}
          ></div>

          <div className="d-flex">
            <div className="py-2">{randomPost.currency_symbol}</div>
            <h1 className="py-0 my-0">{randomPost.price}</h1>
          </div>

          <h4 className="my-3 delivery-info">
            Delivery Fee{randomPost.currency_symbol} 0, Expected to arrive{" "}
            <span id="myDate">{formattedDate}</span>
          </h4>

          <div className="user-ip" style={{ color: "#307083" }}>
            {/* <FontAwesomeIcon icon="fa-solid fa-location-dot" /> {userData.ip} */}
            <FontAwesomeIcon icon={faLocationDot} />
          </div>

          <div>
            <h3 className="mt-3 text-success">We Have Stock Now</h3>
          </div>

          <div
            className="btn individual-btn"
            style={{ border: "solid 1px #161717", borderRadius: "8px" }}
          >
            <h4>5449/Individual</h4>
          </div>

          <div className="d-flex justify-content-center mt-4 mb-2">
            <div className="starContainer">
              {Array.from({ length: 5 }, (_, i) => (
                <i
                  key={i}
                  className={`fa-${
                    i < Math.floor(randomPost.score) ? "solid" : "regular"
                  } fa-star ${
                    i < Math.floor(randomPost.score) ? "text-warning" : ""
                  }`}
                  style={{ color: "#307083" }}
                ></i>
              ))}
            </div>
          </div>

          <div
            className="d-flex justify-content-center comments-count"
            style={{ color: "#307083" }}
          >
            ({orderCount} comments)
          </div>

          <div className="row mt-3 basic-info-section">
            <div style={{ fontWeight: "560" }} className="col-6 text-start">
              Return Of Goods
            </div>
            <div
              className="paymentText col-6 text-start"
              style={{ color: "#307083" }}
            >
              This Product Supports Returns And Exchanges
            </div>
          </div>

          <div className="row col-12 mt-3 payment-section">
            <div className="col-6 text-start">Payment</div>
            <div
              className="paymentText col-6 text-start"
              style={{ color: "#307083" }}
            >
              Secure Transactions
            </div>
          </div>

          <div
            className="w-100 mt-3 separator"
            style={{ border: "solid 1px rgb(135, 133, 133)" }}
          ></div>

          <div className="ms-2 my-2 d-flex">
            <div className="ms-3">
              <div className="d-flex justify-content-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/car.png`}
                  className="info-icon"
                  style={{ width: "60px", height: "60px" }}
                  alt="car"
                />
              </div>
              <div style={{ color: "#307083" }}>Ships From</div>
            </div>
            <div className="ms-3">
              <div className="d-flex justify-content-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/return.png`}
                  className="info-icon"
                  style={{ width: "60px", height: "60px" }}
                  alt="return"
                />
              </div>
              <div style={{ color: "#307083" }}>Returnable until June</div>
            </div>
            <div className="ms-3">
              <div className="d-flex justify-content-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/customerSupport.png`}
                  style={{ width: "60px", height: "60px" }}
                  className="info-icon"
                  alt="customer support"
                />
              </div>
              <div style={{ color: "#307083" }}>Customer Support</div>
            </div>
          </div>

          <div
            className="w-100 mt-3 separator"
            style={{ border: "solid 1px rgb(135, 133, 133)" }}
          ></div>

          <div className="my-4">
            <h5
              className="section-title"
              style={{ color: "black", fontWeight: "600" }}
            >
              Basic Information
            </h5>
            {/* {firstBasicInfoArray?.map((item, index) => (
              <div key={index} className="text-start">
                {item.name} : {secondBasicInfoArray[index].profession}
              </div>
            ))} */}
          </div>

          <div className="my-2">
            <h5
              className="section-title"
              style={{ color: "black", fontWeight: "600" }}
            >
              Commodity Information
            </h5>
            {productInfoArray.map((item, index) => (
              <div key={index} className="d-flex ms-3 text-start">
                <div
                  className="mt-2 info-dot"
                  style={{
                    width: "6px",
                    height: "6px",
                    backgroundColor: "rgb(40, 34, 34)",
                  }}
                ></div>
                <div className="ms-3">{item.productName}</div>
                <div className="ms-2">{item.price}</div>
              </div>
            ))}
          </div>

          <div className="my-3 ms-2">
            <h5
              className="section-title"
              style={{ color: "black", fontWeight: "600" }}
            >
              Describe
            </h5>
            <div className="d-flex position-relative">
              <div
                id="descOrder"
                className="descriptionOrder"
                style={{
                  overflow: "hidden",
                  height: isUnfold ? "50px" : "auto",
                  overflowY: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {randomPost.description}
                <span
                  id="putAway"
                  onClick={() => {
                    setIsUnfold(true);
                  }}
                  className="d-inline unfold"
                  style={{
                    backgroundColor: "white",
                    cursor: "pointer",
                    color: "#1191b9",
                  }}
                >
                  Put Away
                </span>
              </div>
              {isUnfold && (
                <div
                  id="unfold"
                  onClick={() => {
                    setIsUnfold(false);
                  }}
                  className="position-absolute unfold"
                  style={{
                    bottom: "0",
                    right: "0",
                    backgroundColor: "white",
                    cursor: "pointer",
                    color: "#1191b9",
                    zIndex: "2",
                  }}
                >
                  ....Unfold
                </div>
              )}
            </div>
          </div>

          <div
            className="w-100 mt-3 separator"
            style={{ border: "solid 1px rgb(135, 133, 133)" }}
          ></div>

          <div>
            <h5
              className="section-title "
              style={{ color: "black", fontWeight: "600" }}
            >
              Pictures And Videos
            </h5>
            <div className="w-100 d-flex justify-content-center">
              <Carousel style={{ maxWidth: "400px" }}>
                <Carousel.Item style={{ maxWidth: "400px" }}>
                  <img
                    className="d-block w-100"
                    src={`${adminDomain}/public/storage/${encodeURI(
                      randomPost.cover_photo
                    )}`}
                    style={{ maxWidth: "400px" }}
                    alt="First slide"
                  />
                </Carousel.Item>
                {imgArray?.map((item, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={`${adminDomain}/public/storage/${encodeURI(item)}`}
                      alt={`Slide ${index}`}
                      style={{ maxWidth: "400px" }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>

          <div className="mt-3">
            <h5 style={{ color: "black" }} className="section-title">
              Popular Comments From America
            </h5>
            <div className="d-flex">
              <div className="starContainer">
                {Array.from({ length: 5 }, (_, i) => (
                  <i
                    key={i}
                    className={`fa-${
                      i < Math.floor(randomPost.score) ? "solid" : "regular"
                    } fa-star ${
                      i < Math.floor(randomPost.score) ? "text-warning" : ""
                    }`}
                  ></i>
                ))}
              </div>
              <div> 4.1 (Full Score Of 5 Points)</div>
            </div>
            <div className="mt-2">0 Overall Rating Of Items</div>
          </div>

          <div
            className="w-100 mt-3 separator"
            style={{ border: "solid 1px rgb(135, 133, 133)" }}
          ></div>
          <div className="  commentContainer">{comments}</div>
          {/* <div className="mt-3 mb-5">
            <div className="text-center my-5">
              <button
                className="btn btn-lg order-btn"
                style={{ color: "black" }}
              >
                Order Now
              </button>

            </div>
          </div> */}
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div
          className="orderFooter"
          onClick={() => {
            setFooterFirstSection(true);
            setFooterSecondSection(true);
          }}
          style={{ position: "fixed", bottom: 0, zIndex: "10" }}
        >
          {isFooterFirstSection && (
            <div
              id="footerFirstSection"
              className="mt-md-4 mt-3 mb-md-5 mb-2 mx-md-5 mx-1"
            >
              <div className="float-start">
                <h5 className="d-inline ms-3" style={{ fontWeight: 650 }}>
                  Click To Comment:
                </h5>
              </div>
              <div className="float-end d-flex flex-column justify-content-center">
                <div className="starContainer">
                  <FontAwesomeIcon
                    className=" ms-2"
                    onClick={() => {
                      starClearFun();
                      setFirstStar(true);
                    }}
                    style={{
                      color: firstStar && "#ffc107",
                      cursor: "pointer",
                    }}
                    id="firstStar"
                    icon={firstStar ? faSolidStar : faStar}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      starClearFun();
                      setFirstStar(true);
                      setSecondStar(true);
                    }}
                    className=" ms-1"
                    style={{
                      color: secondStar && "#ffc107",
                      cursor: "pointer",
                    }}
                    id="secondStar"
                    icon={secondStar ? faSolidStar : faStar}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      starClearFun();
                      setFirstStar(true);
                      setSecondStar(true);
                      setThirdStar(true);
                    }}
                    className=" ms-1"
                    style={{
                      color: thirdStar && "#ffc107",
                      cursor: "pointer",
                    }}
                    id="thirdStar"
                    icon={thirdStar ? faSolidStar : faStar}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      starClearFun();
                      setFirstStar(true);
                      setSecondStar(true);
                      setThirdStar(true);
                      setFourthStar(true);
                    }}
                    className=" ms-1"
                    style={{
                      color: fourthStar && "#ffc107",
                      cursor: "pointer",
                    }}
                    id="fourthStar"
                    icon={fourthStar ? faSolidStar : faStar}
                  />
                  <FontAwesomeIcon
                    className=" ms-1"
                    onClick={() => {
                      starClearFun();
                      setFirstStar(true);
                      setSecondStar(true);
                      setThirdStar(true);
                      setFourthStar(true);
                      setFifthStar(true);
                    }}
                    style={{
                      color: fifthStar && "#ffc107",
                      cursor: "pointer",
                    }}
                    id="fifthStar"
                    icon={fifthStar ? faSolidStar : faStar}
                  />
                </div>
              </div>
            </div>
          )}
          <br />
          <br />

          {isFooterSEcondSection && (
            <div
              id="footerSecondSection"
              className="position-relative mx-md-5 mx-1"
            >
              <textarea
                id="reviewText"
                value={comment}
                className="p-3 w-100 border-0"
                style={{ resize: "none", height: "100px", outline: "none" }}
                placeholder="Write a Review"
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              ></textarea>
              <button
                onClick={() => {
                  starClearFun();
                  setFirstStar(true);
                  setSecondStar(true);
                  setThirdStar(true);
                  setFourthStar(true);
                  setFifthStar(true);
                  getRandomComment();
                }}
                id="autoReview"
                className="my-2 mx-2 position-absolute"
                style={{
                  bottom: 0,
                  right: 0,
                  fontSize: "small",
                  border: "none",
                  color: "black",
                  fontWeight: 600,
                  backgroundColor: "rgb(241, 187, 14)",
                  borderRadius: "8px",
                }}
              >
                Automatic Review
              </button>
            </div>
          )}
          <div id="footerThirdSection" className="mx-md-5 mx-1">
            <button
              className="py-md-3 py-1 px-3 my-2 w-100"
              style={{
                backgroundColor: "rgb(255, 239, 183)",
                border: "none",
                borderRadius: "6px",
              }}
            >
              <div className="float-start">
                <img
                  src={`${adminDomain}/public/storage/${encodeURI(
                    userData.levelImg
                  )}`}
                  style={{ width: "40px", height: "40px" }}
                  alt=""
                />
                &nbsp;
                <h6
                  className="d-inline mt-3 ps-3"
                  style={{ color: "black", fontWeight: 700 }}
                >
                  {userData.memberLevelName}
                </h6>
              </div>
              <div className="float-end d-flex flex-column justify-content-center">
                <div
                  style={{
                    backgroundColor: "black",
                    borderRadius: "8px",
                    color: "white",
                    fontWeight: 600,
                  }}
                  className="mt-2 px-1"
                >
                  Order quantity: {randomPost.quantity_order}
                </div>
              </div>
            </button>
          </div>

          <div
            id="footerFourthSection"
            className="d-flex justify-content-center mx-md-5 mx-1"
          >
            <div
              className="bg-white my-2 col-12 mt-2 row"
              style={{ borderRadius: "5px" }}
            >
              <div className="col-4">
                <div className="w-100">
                  <div
                    className="w-100"
                    style={{
                      color: "black",
                      fontWeight: 680,
                      fontSize: "0.8rem",
                    }}
                  >
                    Total Amount(USD)
                  </div>
                  <div style={{ color: "black", fontWeight: 600 }}>
                    {formatNumber(
                      randomPost.price * randomPost.quantity_order,
                      2
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex text-center p-0 m-0 justify-content-center col-4">
                <div className="m-0 w-100">
                  <div
                    className="w-100"
                    style={{
                      color: "black",
                      fontWeight: 680,
                      fontSize: "0.8rem",
                    }}
                  >
                    Commision
                  </div>
                  <div style={{ color: "black", fontWeight: 600 }}>
                    {commision}%
                  </div>
                </div>
              </div>
              <div className="col-4 d-flex text-right justify-content-end">
                <div className="w-100">
                  <div
                    className="w-100"
                    style={{
                      color: "black",
                      fontWeight: 680,
                      fontSize: "0.8rem",
                    }}
                  >
                    Commision Revenue(USD)
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: 600,
                    }}
                  >
                    {formatNumber(
                      ((randomPost.price * randomPost.quantity_order) / 100) *
                        commision,
                      2
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="footerFifthSection"
            className="d-flex justify-content-center mx-md-5 mx-1"
          >
            <div
              style={{ borderRadius: "5px" }}
              className="bg-white col-12 mt-2 row"
            >
              <div className="col-6 d-flex justify-content-start">
                <div>
                  <div style={{ color: "black", fontWeight: 600 }}>
                    Created At
                  </div>
                  <div style={{ color: "black", fontWeight: 600 }}>NO :</div>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div>
                  <div style={{ color: "black", fontWeight: 600 }}>
                    {formatTimestamp(time)}
                  </div>
                  <div
                    style={{ color: "black", fontWeight: 600 }}
                    id="randomNumber"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <input
            type="hidden"
            id="hiddenBalance"
            value={userData.wallet_balance}
          />

          <form
            action="YOUR_ACTION_URL"
            method="POST"
            id="footerSixthSection"
            className="mt-4 mx-md-5 mx-1"
          >
            <input
              hidden
              name="commision"
              type="text"
              value={formatNumber(345.566 / 100, 2)}
            />
            <input type="hidden" hidden name="id" value="23" />
            <input type="hidden" name="percent" value="34.3" />
            <input
              type="hidden"
              name="plusAmount"
              value={randomPost.price * randomPost.quantity_order}
            />
            <button
              id="fakeSubmit"
              type="button"
              onClick={!isLoading && postOrder}
              className="w-100 mb-4 py-2 border-0 text-white"
              style={{ backgroundColor: "black", borderRadius: "5px" }}
            >
              <h5>Start Order</h5>
            </button>
            <button id="realSubmit" type="submit" hidden>
              start
            </button>
          </form>
        </div>

        <div
          id="errorModal"
          className="withdrawal-modal"
          style={{ zIndex: 5000 }}
        >
          <div
            className="withdrawal-modal-content withdrawlModal d-flex justify-content-center"
            style={{ opacity: 0.7, background: "#333", display: "flex" }}
          >
            <div id="countDownText" className="d-flex">
              {/* <div className="loader"></div> */}
              <h6
                className="text-center ms-2 mt-2"
                style={{ color: "white", fontWeight: 600 }}
              >
                Insufficient Balance
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div
        id="withdrawalModal"
        class="withdrawal-modal "
        style={{ zIndex: "5000", display: isLoading && "flex" }}
      >
        <div
          class="withdrawal-modal-content withdrawlModal  d-flex  justify-content-center "
          style={{ opacity: "0.7", background: "#333" }}
        >
          <div id="countDownText" class=" d-flex">
            <div class="loader"></div>
            <h6
              class="text-center ml-2 mt-2 "
              style={{ color: "white", fontWeight: "600" }}
            >
              {" "}
              Countdown Begins{" "}
            </h6>
          </div>
        </div>
      </div>
      <div
        id="withdrawalModal"
        class="withdrawal-modal "
        style={{ zIndex: "5000", display: isSuccess && "flex" }}
      >
        <div
          class="withdrawal-modal-content withdrawlModal  d-flex  justify-content-center "
          style={{ opacity: "0.7", background: "#333" }}
        >
          <div id="countDownText" class=" d-flex">
            <h6
              class="text-center ml-2 mt-2 "
              style={{ color: "white", fontWeight: "600" }}
            >
              {" "}
              Order Optimization Success
            </h6>
          </div>
        </div>
      </div>

      <div
        id="withdrawalModal"
        class="withdrawal-modal "
        style={{ zIndex: "5000", display: insufficient && "flex" }}
      >
        <div
          class="withdrawal-modal-content withdrawlModal  d-flex  justify-content-center "
          style={{ opacity: "0.7", background: "#333" }}
        >
          <div id="countDownText" class=" d-flex">
            <h6
              class="text-center ml-2 mt-2 "
              style={{ color: "white", fontWeight: "600" }}
            >
              {" "}
              Insufficient Balance
            </h6>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <>
          <div
            id="modalOverlay"
            className="modal-overlay"
            style={{
              display: "block",
              position: "fixed",
              zIndex: 1,
              left: 0,
              top: 0,
              zIndex: "7000",
              width: "100%",
            }}
          ></div>

          <div
            id="myModal"
            className="modal"
            style={{
              display: "flex",
              position: "fixed",
              zIndex: 2,
              zIndex: "7000",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="modal-content"
              style={{
                position: "relative",
              }}
            >
              <div
                className="image-container m-0 p-0"
                style={{ position: "relative", width: "100%" }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/firstTime.jpg`}
                  alt="Gifting Image"
                  style={{
                    width: "100%",
                    // position: "absolute",
                    height: "100%",
                    display: "block",
                    zIndex: "300",
                  }}
                />
                <div
                  className="text-button-container"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    textAlign: "center",
                    zIndex: 1000,
                    background: "#faf0e6",
                    padding: "15px",
                    boxSizing: "border-box",
                    borderTop:
                      "1px solid #ddd" /* Light border for separation */,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      color: "#333" /* Dark text color for readability */,
                      fontWeight: "bold",
                      fontSize: "18px" /* Slightly larger font for emphasis */,
                    }}
                  >
                    Congratulations! You've been gifted ${Math.round(amount)}!
                  </p>
                  <button
                    className="action-button"
                    onClick={claimGift}
                    style={{
                      marginTop: "10px",
                      padding: "10px 20px",
                      backgroundColor: "#ffa23c" /* Bright orange color */,
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px" /* Consistent button font size */,
                    }}
                  >
                    Claim
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderPage;
