import axios from "axios";
import bcrypt from "bcryptjs";
import { CiSearch } from "react-icons/ci";
import { apiCall } from "../utils/apiCall";
import { useEffect } from "react";
import { useState } from "react";
import "./../index.css";

import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { alphabets } from "../utils/alphabet";
import {
  getId,
  getName,
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import CommisionStatementPage from "./commisionStatement";
import { faL } from "@fortawesome/free-solid-svg-icons";

export const Home = () => {
  const [levelName, setLevelName] = useState("");
  const navigate = useNavigate();
  const [isFirstImg, setFirstImg] = useState(false);
  const [isFirstTimeModal, setIsFirstTimeModal] = useState(false);
  const [firstCurrentIndex, setFirstCurrentIndex] = useState(0);
  const [secondCurrentIndex, setSecondCurrentIndex] = useState(0);
  const [stickIndex, setStickIndex] = useState(0); // Adjusted initial index
  const [users, setUsers] = useState(3.987);
  const [orders, setOrders] = useState(5.965);
  const [quantity, setQuantity] = useState(3.987);
  const [demands, setDemands] = useState(1.3);
  const [isToday, setIsToday] = useState(true);
  const [isTotal, setIsTotal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [wallet, setWallet] = useState("");
  const [todayPrice, setTodayPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [todayTeam, setTodayTeam] = useState(0);
  const [totalTeam, setTotalTeam] = useState(0);
  const [levelImg, setLevelImg] = useState("");
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [tranPassword, setTranPassword] = useState("");
  const [myHashedPassword, setMyHashedPassword] = useState("");
  const [falseTranPass, setFalseTranPass] = useState(false);
  const [errorTransactionPassword, setErrorTransactionPassword] =
    useState(false);

  function checkPassword(plainPassword, hashedPassword) {
    return bcrypt.compareSync(plainPassword, hashedPassword);
  }
  const toWithdraw = () => {
    if (tranPassword == "") {
      setErrorTransactionPassword(true);
      return;
    }
    setTranPassword("");

    if (checkPassword(tranPassword, myHashedPassword)) {
      navigate("/withdraw");
    } else {
      setFalseTranPass(true);
      setTimeout(() => {
        setFalseTranPass(false);
      }, 2000);
    }
    setIsWithdraw(false);
  };
  const todayClicked = () => {
    setIsTotal(false);
    setIsToday(true);
  };
  const totalClicked = () => {
    setIsToday(false);
    setIsTotal(true);
  };
  const userData = JSON.parse(getUser());
  const token = getToken();

  const adminDomain = "https://admintesting.amzscoutmembers.com";
  const domain = "https://usertesting.amzscoutmembers.com";
  // arrays start
  const stickImg = [
    "/images/No need to edit/smallAnimation/download (1).png",
    "/images/No need to edit/smallAnimation/download (2).png",
    "/images/No need to edit/smallAnimation/download (3).png",
    "/images/No need to edit/smallAnimation/download (4).png",
    "/images/No need to edit/smallAnimation/download (5).png",
    "/images/No need to edit/smallAnimation/download (6).png",
    "/images/No need to edit/smallAnimation/download (7).png",
    "/images/No need to edit/smallAnimation/download (8).png",
    "/images/No need to edit/smallAnimation/download (9).png",
    "/images/No need to edit/smallAnimation/download (10).png",
    "/images/No need to edit/smallAnimation/download (11).png",
    "/images/No need to edit/smallAnimation/download (12).png",
    "/images/No need to edit/smallAnimation/download (13).png",
    "/images/No need to edit/smallAnimation/download (14).png",
    "/images/No need to edit/smallAnimation/download (15).png",
    "/images/No need to edit/smallAnimation/download (16).png",
    "/images/No need to edit/smallAnimation/download (17).png",
    "/images/No need to edit/smallAnimation/download (18).png",
    "/images/No need to edit/smallAnimation/download (19).png",
    "/images/No need to edit/smallAnimation/download (20).png",
  ].map((path) => domain + path);

  const firstImg = [
    "/images/No need to edit/largeAnimation/download (1).png",
    "/images/No need to edit/largeAnimation/download (2).png",
    "/images/No need to edit/largeAnimation/download (3).png",
    "/images/No need to edit/largeAnimation/download (4).png",
    "/images/No need to edit/largeAnimation/download (5).png",
    "/images/No need to edit/largeAnimation/download (6).png",
    "/images/No need to edit/largeAnimation/download (7).png",
    "/images/No need to edit/largeAnimation/download (8).png",
    "/images/No need to edit/largeAnimation/download (9).png",
    "/images/No need to edit/largeAnimation/download (10).png",
    "/images/No need to edit/largeAnimation/download (11).png",
    "/images/No need to edit/largeAnimation/download (12).png",
    "/images/No need to edit/largeAnimation/download (13).png",
    "/images/No need to edit/largeAnimation/download (14).png",
    "/images/No need to edit/largeAnimation/download (15).png",
    "/images/No need to edit/largeAnimation/download (16).png",
    "/images/No need to edit/largeAnimation/download (19).png",
    "/images/No need to edit/largeAnimation/download (20).png",
    "/images/No need to edit/largeAnimation/download (21).png",
    "/images/No need to edit/largeAnimation/download (22).png",
    "/images/No need to edit/largeAnimation/download (23).png",
    "/images/No need to edit/largeAnimation/download (24).png",
    "/images/No need to edit/largeAnimation/download (25).png",
    "/images/No need to edit/largeAnimation/download (26).png",
    "/images/No need to edit/largeAnimation/download (27).png",
    "/images/No need to edit/largeAnimation/download (28).png",
    "/images/No need to edit/largeAnimation/download (29).png",
    "/images/No need to edit/largeAnimation/download (30).png",
    "/images/No need to edit/largeAnimation/download (31).png",
    "/images/No need to edit/largeAnimation/download (32).png",
    "/images/No need to edit/largeAnimation/download (33).png",
    "/images/No need to edit/largeAnimation/download (34).png",
    "/images/No need to edit/largeAnimation/download (35).png",
    "/images/No need to edit/largeAnimation/download (36).png",
    "/images/No need to edit/largeAnimation/download (37).png",
    "/images/No need to edit/largeAnimation/download (38).png",
    "/images/No need to edit/largeAnimation/download (39).png",
  ].map((path) => domain + path);

  const secondImg = [
    "/images/No need to edit/largeAnimation/download (39).png",
    "/images/No need to edit/largeAnimation/download (38).png",
    "/images/No need to edit/largeAnimation/download (37).png",
    "/images/No need to edit/largeAnimation/download (36).png",
    "/images/No need to edit/largeAnimation/download (35).png",
    "/images/No need to edit/largeAnimation/download (34).png",
    "/images/No need to edit/largeAnimation/download (33).png",
    "/images/No need to edit/largeAnimation/download (32).png",
    "/images/No need to edit/largeAnimation/download (31).png",
    "/images/No need to edit/largeAnimation/download (30).png",
    "/images/No need to edit/largeAnimation/download (29).png",
    "/images/No need to edit/largeAnimation/download (28).png",
    "/images/No need to edit/largeAnimation/download (27).png",
    "/images/No need to edit/largeAnimation/download (26).png",
    "/images/No need to edit/largeAnimation/download (25).png",
    "/images/No need to edit/largeAnimation/download (24).png",
    "/images/No need to edit/largeAnimation/download (23).png",
    "/images/No need to edit/largeAnimation/download (22).png",
    "/images/No need to edit/largeAnimation/download (21).png",
    "/images/No need to edit/largeAnimation/download (20).png",
    "/images/No need to edit/largeAnimation/download (19).png",
    "/images/No need to edit/largeAnimation/download (16).png",
    "/images/No need to edit/largeAnimation/download (15).png",
    "/images/No need to edit/largeAnimation/download (14).png",
    "/images/No need to edit/largeAnimation/download (13).png",
    "/images/No need to edit/largeAnimation/download (12).png",
    "/images/No need to edit/largeAnimation/download (11).png",
    "/images/No need to edit/largeAnimation/download (10).png",
    "/images/No need to edit/largeAnimation/download (9).png",
    "/images/No need to edit/largeAnimation/download (8).png",
    "/images/No need to edit/largeAnimation/download (7).png",
    "/images/No need to edit/largeAnimation/download (6).png",
    "/images/No need to edit/largeAnimation/download (5).png",
    "/images/No need to edit/largeAnimation/download (4).png",
    "/images/No need to edit/largeAnimation/download (3).png",
    "/images/No need to edit/largeAnimation/download (2).png",
    "/images/No need to edit/largeAnimation/download (1).png",
  ].map((path) => domain + path);

  const commissions = [
    "JohnDoe123 receives a commission of $843.21 USD",
    "JaneSmith456 receives a commission of $1,945.78 USD",
    "MikeJohnson789 receives a commission of $756.34 USD",
    "EmilyBrown321 receives a commission of $3,529.67 USD",
    "ChrisDavis654 receives a commission of $913.45 USD",
    "SarahWilson987 receives a commission of $634.89 USD",
    "DavidClark123 receives a commission of $2,617.89 USD",
    "LauraMartinez456 receives a commission of $1,812.90 USD",
    "RobertLee789 receives a commission of $957.36 USD",
    "JessicaGarcia321 receives a commission of $3,178.89 USD",
    "DanielLopez654 receives a commission of $723.18 USD",
    "LisaAnderson987 receives a commission of $890.47 USD",
    "KevinHernandez123 receives a commission of $4,567.89 USD",
    "AmyHall456 receives a commission of $917.29 USD",
    "StevenYoung789 receives a commission of $745.36 USD",
    "MeganKing321 receives a commission of $1,861.92 USD",
    "BrianWright654 receives a commission of $980.13 USD",
    "RachelScott987 receives a commission of $754.68 USD",
    "CharlesGreen123 receives a commission of $834.21 USD",
    "KarenBaker456 receives a commission of $905.78 USD",
    "GeorgeAdams789 receives a commission of $725.34 USD",
    "LauraNelson321 receives a commission of $4,412.34 USD",
    "FrankCarter654 receives a commission of $946.45 USD",
    "SusanMitchell987 receives a commission of $645.89 USD",
    "PaulPerez123 receives a commission of $765.23 USD",
    "NancyRoberts456 receives a commission of $1,812.90 USD",
    "PatrickTurner789 receives a commission of $957.36 USD",
    "KimberlyPhillips321 receives a commission of $5,678.45 USD",
    "AndrewCampbell654 receives a commission of $723.18 USD",
    "MichelleParker987 receives a commission of $890.47 USD",
    "ThomasEvans123 receives a commission of $834.65 USD",
    "StephanieEdwards456 receives a commission of $917.29 USD",
    "JoshuaCollins789 receives a commission of $745.36 USD",
    "HeatherStewart321 receives a commission of $3,861.92 USD",
    "ScottSanchez654 receives a commission of $980.13 USD",
    "AngelaMorris987 receives a commission of $754.68 USD",
    "BrandonRogers123 receives a commission of $6,567.89 USD",
    "NicoleReed456 receives a commission of $905.78 USD",
    "JasonCook789 receives a commission of $725.34 USD",
    "MichelleMorgan321 receives a commission of $3,289.45 USD",
    "GregoryBell654 receives a commission of $946.45 USD",
    "EmilyMurphy987 receives a commission of $645.89 USD",
    "AlexanderBailey123 receives a commission of $1,765.23 USD",
    "MadisonRivera456 receives a commission of $812.90 USD",
    "BenjaminCooper789 receives a commission of $957.36 USD",
    "OliviaRichardson321 receives a commission of $678.55 USD",
    "AdamCox654 receives a commission of $723.18 USD",
    "AshleyHoward987 receives a commission of $890.47 USD",
    "RyanWard123 receives a commission of $834.65 USD",
    "AbigailTorres456 receives a commission of $2,917.29 USD",
  ];

  const checkLink = async () => {
    try {
      const response = await axios.get(
        "https://admintesting.amzscoutmembers.com/api/customerService",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.data.status == "true") {
        if (response.data.isLink == "true") {
          window.location.href = response.data.link;
        } else {
          navigate("/customerService");
        }
      }

      // console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const firstGiftFun = async () => {
    try {
      const response = await axios.post(
        "https://admintesting.amzscoutmembers.com/api/firstGift",
        {
          id: userData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      setWallet(response.data.data.wallet_balance);

      // console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // arrays end

  // function start

  const hideFirstTimeModal = () => {
    setIsFirstTimeModal(false);
  };

  const hideFirstImg = () => {
    setFirstImg(!isFirstImg);
  };

  const stickImgChange = () => {
    setStickIndex((prevIndex) => (prevIndex + 1) % stickImg.length);
  };

  const firstAnimationChange = () => {
    setFirstCurrentIndex((prevIndex) => (prevIndex + 1) % firstImg.length);
  };

  const secondAnimationChange = () => {
    setSecondCurrentIndex((prevIndex) => (prevIndex + 1) % secondImg.length);
  };

  const numberPlus = () => {
    setUsers((prev) => Math.round((prev + 0.003) * 1000) / 1000);
    setOrders((prev) => Math.round((prev + 0.002) * 1000) / 1000);
    setQuantity((prev) => Math.round((prev + 0.004) * 1000) / 1000);
    setDemands((prev) => Math.round((prev + 0.002) * 1000) / 1000);
  };

  const fetchData = async () => {
    const imgModal = localStorage.getItem("firstImgModal");
    if (!imgModal) {
      setFirstImg(true);
      localStorage.setItem("firstImgModal", "true");
    }
    if (!token) {
      navigate("/login");
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://admintesting.amzscoutmembers.com/api/home",
        {
          id: userData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setMyHashedPassword(response.data.data.transactionPassword);
      setIsLoading(false);
      setInviteCode(response.data.data.invite_code);
      setWallet(response.data.data.wallet_balance);
      setTodayPrice(response.data.data.todayPrice);
      setTodayTeam(response.data.data.todayTeam);
      setTotalPrice(response.data.data.totalPrice);
      setTotalTeam(response.data.data.totalTeam);
      setLevelName(response.data.data.memberLevelName);

      setLevelImg(response.data.data.levelImg);
      if (response.data.data.firstGift == null) {
        setIsFirstTimeModal(true);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      stickImgChange();
      firstAnimationChange();
      secondAnimationChange();
      numberPlus();
    }, 2000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // return isLoading ? (
  //   <div class="skeleton">
  //     <style>
  //       {`
  // .skeleton {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 10px;
  //     padding: 20px;
  //   }

  //   .skeleton-header,
  //   .skeleton-content,
  //   .skeleton-footer,
  //   .skeleton-avatar,
  //   .skeleton-text,
  //   .skeleton-button,
  //   .skeleton-card {
  //     background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  //     background-size: 200% 100%;
  //     animation: loading 1.5s infinite;
  //   }

  //   .skeleton-header {
  //     height: 20px;
  //     width: 100%;
  //     margin-bottom: 10px;
  //   }

  //   .skeleton-content {
  //     height: 100px;
  //     width: 100%;
  //   }

  //   .skeleton-footer {
  //     height: 20px;
  //     width: 100%;
  //     margin-top: 10px;
  //   }

  //   .skeleton-avatar {
  //     height: 50px;
  //     width: 50px;
  //     border-radius: 50%;
  //     margin-bottom: 10px;
  //   }

  //   .skeleton-text {
  //     height: 15px;
  //     width: 80%;
  //     margin-bottom: 5px;
  //   }

  //   .skeleton-button {
  //     height: 40px;
  //     width: 100px;
  //     border-radius: 5px;
  //     margin-top: 10px;
  //   }

  //   .skeleton-card {
  //     height: 150px;
  //     width: 100%;
  //     border-radius: 10px;
  //     margin-top: 10px;
  //   }

  //   @keyframes loading {
  //     0% {
  //       background-position: 200% 0;
  //     }
  //     100% {
  //       background-position: -200% 0;
  //     }
  //   }

  //   /* Responsive adjustments */
  //   @media (max-width: 600px) {
  //     .skeleton-header {
  //       height: 15px;
  //     }

  //     .skeleton-content {
  //       height: 80px;
  //     }

  //     .skeleton-footer {
  //       height: 15px;
  //     }

  //     .skeleton-avatar {
  //       height: 40px;
  //       width: 40px;
  //     }

  //     .skeleton-text {
  //       height: 12px;
  //     }

  //     .skeleton-button {
  //       height: 35px;
  //       width: 80px;
  //     }

  //     .skeleton-card {
  //       height: 120px;
  //     }
  //   }
  // `}
  //     </style>

  //     <div class="skeleton-avatar"></div>
  //     <div class="skeleton-header"></div>
  //     <div class="skeleton-text"></div>
  //     <div class="skeleton-text"></div>
  //     <div class="skeleton-content"></div>
  //     <div class="skeleton-footer"></div>
  //     <div class="skeleton-button"></div>
  //     <div class="skeleton-card"></div>
  //   </div>
  // ) : (

  return (
    <div
      className="homeContainer pt-2 ps-1 bg-white"
      style={{ backgroundColor: "#f7f7f7" }}
    >
      {/* style start */}

      <style>
        {`
             .modal {
            display: block;
            /* display: none; Hidden by default */
            position: fixed; /* Stay in place */
            z-index: 1000; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
            transition: opacity 0.3s ease;
        }
    .notification {
      opacity: 0.6;
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #333;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      z-index: 1000;
    }
    .firstTime-modal {
      display: block;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgba(0,0,0,0.4);
    }
    .firstTime-modal-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 20px;
      border: 1px solid #888;
      width: 80%;
      max-width: 500px;
      text-align: center;
      position: relative;
    }
    .firstTime-close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .firstTime-close:hover,
    .firstTime-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
    .firstTime-confirm-btn {
      background-color: black;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 3px;
      cursor: pointer;
      margin-top: 20px;
    }
    .firstTime-confirm-btn:hover {
      background-color: #333;
    }
      
        .modal-content {
            position: relative;
            margin: 1% auto; /* 10% from top and auto for left/right alignment */
            width: 70%; /* Could be more or less depending on your design */
            max-width: 500px; /* Max width of the modal */
            background-color: #fff; /* White background for the content */
            border-radius: 10px;
            overflow: hidden;
          
        }
        @media (max-width: 600px) {
    .modal-content {
        position: relative;
        margin: 20% auto; /* 10% from top and auto for left/right alignment */
        width: 80%; /* Could be more or less depending on your design */
        max-width: 500px; /* Max width of the modal */
        background-color: #fff; /* White background for the content */
        border-radius: 10px;
        overflow: hidden;
    }
}

@media (max-width: 400px) {
    .modal-content {
        position: relative;
        margin: 40% auto; /* 10% from top and auto for left/right alignment */
        width: 80%; /* Could be more or less depending on your design */
        max-width: 500px; /* Max width of the modal */
        background-color: #fff; /* White background for the content */
        border-radius: 10px;
        overflow: hidden;
    }
}
  `}
      </style>

      {/* style end */}
      <div className="homeLogoContainer">
        <img
          src={`${process.env.PUBLIC_URL}/images/No need to edit/js.png`}
          // src="{{asset('icons/No need to edit/js.png')}}"
          className="pe-2 ms-3"
          style={{ width: "50px", height: "40px" }}
          alt=""
        />
      </div>

      <div
        className="mt-1 marqueeContainer px-3"
        style={{ backgroundColor: "rgb(241, 187, 14)" }}
      >
        <marquee
          id="myMarquee"
          behavior=""
          scrollamount="10"
          direction="ltr"
          style={{ width: "100%" }}
          className="d-flex justify-content-around"
        >
          {commissions?.map((eachObj, index) => (
            <div key={index} className=" me-4 marquee-content d-inline ">
              {eachObj}
            </div>
          ))}
        </marquee>
      </div>

      <div className="mt-3 mx-md-3 mx-1 homeBigCardContainer">
        <div
          className="py-3 px-md-3 px-2 homeBigCard"
          style={{
            width: "100%",
            backgroundColor: "black",
            borderRadius: "10px",
          }}
        >
          <h6 style={{ fontWeight: 600 }} className="my-0 py-0 text-white">
            Wallet Account
          </h6>
          <div className="d-flex text-white">
            <h3
              style={{ fontStyle: "italic", fontWeight: 700 }}
              className="mt-2"
            >
              {wallet}
            </h3>
            <div className="mt-3 pt-1" style={{ fontSize: ".8rem" }}>
              USD
            </div>
          </div>

          <div className="mt-2 homeBtnContainer">
            <button
              onClick={() => {
                todayClicked();
              }}
              id="firstToday"
              className="px-1"
              style={{
                backgroundColor: isToday ? "rgb(241, 187, 14)" : "black",
                color: isToday ? "black" : "white",
                borderRadius: "3px",
                paddingTop: "2px",
                border: isToday ? "none" : "white solid .4px",
                paddingBottom: "2px",

                fontSize: ".8rem",
                outline: "none",
              }}
            >
              Today's Earnings
            </button>
            <button
              id="firstTotal"
              onClick={() => {
                totalClicked();
              }}
              className="ms-3 px-1  "
              style={{
                paddingTop: "2px",
                paddingBottom: "2px",
                fontSize: ".8rem",
                outline: "none",
                border: isTotal ? "none" : "white solid .4px",
                backgroundColor: isTotal ? "rgb(241, 187, 14)" : "black",
                color: isTotal ? "black" : "white",
                borderRadius: "3px",
              }}
            >
              Total Earnings
            </button>
            {/* <button
        id="secondTotal"
        className="px-1  text-white"
        style={{

          paddingTop: "2px",
          paddingBottom: "2px",
          fontSize: ".8rem",
          outline: "none",
          backgroundColor: "black",
          borderRadius: "3px",
          display: "none",
        }}
      >
        Todays Earnings
      </button>
      <button
        id="secondToday"
        className="ms-3 px-1"
        style={{
          display: "none",
          backgroundColor: "rgb(241, 187, 14)",
          borderRadius: "3px",
          paddingTop: "2px",
          paddingBottom: "2px",
          border: 0,
          fontSize: ".8rem",
          outline: "none",
        }}
      >
        Total Earnings
      </button> */}
          </div>

          <div className="homeCasesContainer d-flex mt-md-2 mt-2">
            <div
              id="firstOrder"
              className="revenueContainer me-3 pe-2"
              style={{
                display: "inline",
                borderRight: "solid 1px rgb(241, 187, 14)",
              }}
            >
              <div
                style={{
                  color: "rgb(197, 196, 196)",
                  fontSize: ".8rem",
                  fontWeight: 460,
                }}
              >
                Order Revenue
              </div>
              <div className="text-white">
                {isToday ? todayPrice.toFixed(3) : totalPrice.toFixed(3)}
              </div>
            </div>
            <div
              id="firstTeam"
              className="teamContainer me-3 pe-2"
              style={{
                display: "inline",
                borderRight: "solid 1px rgb(241, 187, 14)",
              }}
            >
              <div
                style={{
                  color: "rgb(197, 196, 196)",
                  fontSize: ".8rem",
                  fontWeight: 460,
                }}
              >
                Team
              </div>
              <div className="text-white">
                {" "}
                {isToday ? todayTeam.toFixed(3) : totalTeam.toFixed(3)}{" "}
              </div>
            </div>
            <div
              id="firstEarning"
              style={{ display: "inline" }}
              className="totalContainer me-3 pe-2"
            >
              <div
                style={{
                  color: "rgb(197, 196, 196)",
                  fontSize: ".8rem",
                  fontWeight: 460,
                }}
              >
                Total Earnings
              </div>
              <div className="text-white">
                {" "}
                {isToday
                  ? (todayPrice + todayTeam).toFixed(3)
                  : (totalPrice + totalTeam).toFixed(3)}{" "}
              </div>
            </div>
            <div
              id="secondOrder"
              className="revenueContainer me-3 pe-2"
              style={{
                display: "none",
                borderRight: "solid 1px rgb(241, 187, 14)",
              }}
            >
              <div
                style={{
                  color: "rgb(197, 196, 196)",
                  fontSize: ".8rem",
                  fontWeight: 460,
                }}
              >
                Order Revenue
              </div>
              <div className="text-white">345.2</div>
            </div>
            <div
              id="secondTeam"
              className="teamContainer me-3 pe-2"
              style={{
                display: "none",
                borderRight: "solid 1px rgb(241, 187, 14)",
              }}
            >
              <div
                style={{
                  color: "rgb(197, 196, 196)",
                  fontSize: ".8rem",
                  fontWeight: 460,
                }}
              >
                Team
              </div>
              <div className="text-white">345.23</div>
            </div>
            <div
              id="secondEarning"
              className="totalContainer me-3 pe-2"
              style={{ display: "none" }}
            >
              <div
                style={{
                  color: "rgb(197, 196, 196)",
                  fontSize: ".8rem",
                  fontWeight: 460,
                }}
              >
                Total Earnings
              </div>
              <div className="text-white">342.22</div>
            </div>
          </div>
        </div>
      </div>

      <div className="homeIconContainer px-0 w-100 mt-md-4 mt-4">
        <div className="col-12 row p-0 m-0 d-flex">
          <div id="withdrawalOpenModalBtn" className="col-3 eachIcon px-0">
            <div
              onClick={() => {
                setIsWithdraw(true);
              }}
              className="d-flex justify-content-center"
            >
              <img
                id="withdrawl"
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Withdrawal logo.png`}
                // src="{{asset('icons/Change them into Red/Withdrawal logo.png')}}"
                alt=""
                className="homeLogos"
              />
            </div>
            <div className="text-md-center text-center homeLogosText">
              Withdrawal
            </div>
          </div>
          <div className="col-3 eachIcon">
            <div
              onClick={() => {
                navigate("Deposit");
              }}
              className="d-flex justify-content-center"
            >
              <img
                id="deposit"
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Deposit logo.png`}
                // src="{{asset('icons/Change them into Red/Deposit logo.png')}}"
                alt=""
                className="homeLogos"
              />
            </div>
            <div className="text-center homeLogosText">Deposit</div>
          </div>
          <div className="col-3 eachIcon px-0">
            <div
              onClick={() => {
                checkLink();
              }}
              className="d-flex justify-content-center"
            >
              <img
                id="customerServiceCenter"
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Customer service logo.png`}
                // src="{{asset('icons/Change them into Red/Customer service logo.png')}}"
                alt=""
                className="homeLogos"
              />
            </div>
            <div className="w-100">
              <div className="text-md-center text-center homeLogosText longText">
                Customer Service Center
              </div>
            </div>
          </div>
          <div className="col-3 eachIcon mx-0">
            <div
              onClick={() => {
                navigate("notice");
              }}
              className="d-flex justify-content-center"
            >
              <img
                id="transactionNotice"
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/transaction logo.png`}
                // src="{{asset('icons/Change them into Red/transaction logo.png')}}"
                alt=""
                className="homeLogos"
              />
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-md-center text-start homeLogosText longText">
                Transaction Notice
              </div>
            </div>
          </div>
        </div>

        <div className="mt-md-4 mt-4 col-12 m-0 p-0 row d-flex">
          <div className="col-3 eachIcon px-0">
            <div
              onClick={() => {
                navigate("event");
              }}
              className="d-flex justify-content-center"
            >
              <img
                id="campaign"
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Campaign logo.png`}
                // src="{{asset('icons/Change them into Red/Campaign logo.png')}}"
                alt=""
                className="homeLogos"
              />
            </div>
            <div className="text-center homeLogosText">Campaign</div>
          </div>
          <div className="col-3 eachIcon px-0">
            <div
              onClick={() => {
                navigate("clause");
              }}
              className="d-flex justify-content-center"
            >
              <img
                id="illustrate"
                // src="{{asset('icons/Change them into Red/Illustrate logo.png')}}"
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Illustrate logo.png`}
                alt=""
                className="homeLogos"
              />
            </div>
            <div className="text-center homeLogosText">Illustrate</div>
          </div>
          <div className="px-md-5 px-0 col-3 eachIcon">
            <div
              onClick={() => {
                navigate("faqs");
              }}
              className="d-flex justify-content-center"
            >
              <img
                id="faqs"
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/FAQS Logo.png`}
                // src="{{asset('icons/Change them into Red/FAQS Logo.png')}}"
                alt=""
                className="homeLogos"
              />
            </div>
            <div className="homeLogosText text-center">FAQs</div>
          </div>
          <div className="col-3 eachIcon px-0">
            <div
              onClick={() => {
                navigate("about");
              }}
              className="d-flex justify-content-center"
            >
              <img
                id="agencyPolicy"
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Company Profile logo.png`}
                alt=""
                className="homeLogos"
              />
            </div>
            <div className="homeLogosText text-center">About</div>
          </div>
        </div>
      </div>
      <div className="my-3 animationContainer mx-md-3 mx-1 d-flex justify-content-between">
        <div className="eachAnimation">
          <div className="smallAnimationCard py-md-2 py-0 px-1">
            <small
              className="ms-2 text-start "
              style={{ fontWeight: 460, color: "black" }}
            >
              Online Users
            </small>
            <h5
              style={{ fontWeight: 650, color: "black" }}
              className="ms-2 my-md-1 my-0"
              id="userCount"
            >
              {users}
            </h5>
            <div className="ps-2 w-100" style={{ fontSize: "x-small" }}>
              +159% from last month
            </div>
          </div>

          <div className="my-md-4 my-3 smallAnimationCard py-md-2 py-0 px-2 position-relative">
            <small
              className="s-2 text-start"
              style={{ color: "black", fontWeight: 460 }}
            >
              Order Completion
            </small>
            <h5
              style={{ fontWeight: 750, color: "black" }}
              className="my-1 ms-2"
              id="orderCount"
            >
              {orders}K
            </h5>
            <div className="mb-2 d-flex">
              <small
                style={{
                  backgroundColor: "black",
                  borderRadius: "2px",
                  fontSize: ".5rem",
                }}
                className="ms-2 px-1 text-white"
              >
                50% <i className="fa-solid fa-up-long text-white"></i>
              </small>
              <div className="animationContainer overflow-hidden">
                <img
                  id="animationStick"
                  // src={`${process.env.PUBLIC_URL}/images/No%20need%20to%20edit/smallAnimation/download%20(19).png`}
                  src={stickImg[stickIndex]}
                  width="87"
                  height="95"
                  className="stick"
                  alt="Animation Stick"
                />
                <div
                  className="coverDiv bg-white position-absolute"
                  style={{
                    right: "-100%",
                    bottom: "0",
                    width: "100px",
                    height: "38px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden largeAnimationCard py-md-2 py-0 px-1 position-relative">
          <small className="text-start w-100  ms-1 optimizeDemand">
            Optimize Demand
          </small>
          <h5
            style={{ fontWeight: 750, color: "black" }}
            className="ms-1 my-md-1 my-0"
            id="demandCount"
          >
            {demands}K
          </h5>
          <small style={{ fontSize: "x-small" }} className="ms-1">
            +159% from last month
          </small>
          <div>
            <img
              id="animationOne"
              src={firstImg[firstCurrentIndex]}
              className="position-absolute"
              style={{
                width: "100%",
                borderRadius: "12px",
                bottom: "0",
                left: "0",
              }}
              alt="Animation One"
            />
            <div
              className="coverDiv bg-white position-absolute"
              style={{
                right: "-100%",
                bottom: "0",
                width: "100px",
                height: "50%",
              }}
            ></div>
          </div>
        </div>

        <div className="largeAnimationCard py-md-2 py-0 px-1 position-relative">
          <small className="ms-1 text-start optimizeDemand">
            Order Quantity
          </small>
          <h5
            style={{ fontWeight: 650, color: "black" }}
            className="py-0 ms-1 my-0 my-md-0"
            id="quantityCount"
          >
            {quantity}K
          </h5>
          <small
            style={{
              backgroundColor: "black",
              borderRadius: "2px",
              fontSize: ".7rem",
            }}
            className="ms-2 mt-0 px-1 text-white"
          >
            63% <i className="fa-solid fa-up-long text-white"></i>
          </small>
          <div>
            <img
              id="animationTwo"
              src={secondImg[secondCurrentIndex]}
              className="position-absolute"
              style={{
                width: "100%",
                borderRadius: "12px",
                bottom: "0",
                left: "0",
              }}
              alt="Animation Two"
            />
            <div
              className="coverDiv bg-white position-absolute"
              style={{
                right: "-100%",
                bottom: "0",
                width: "100px",
                height: "50%",
              }}
            ></div>
          </div>
        </div>
      </div>

      <div className="vipContainer">
        <h5
          className="m-0 p-0 text-start"
          style={{ color: "black", fontWeight: 700 }}
        >
          VIP levels
        </h5>
        <button
          onClick={() => {
            navigate("vipLevel");
          }}
          id="vipLevelBtn"
          className="py-2 px-3 my-md-3 my-1 w-100"
          style={{
            outline: "none",
            backgroundColor: "rgb(241, 187, 14)",
            border: "none",
            borderRadius: "6px",
          }}
        >
          <div className="float-start">
            <img
              src={`${adminDomain}/public/storage/${encodeURI(levelImg)}`}
              // src={`${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`}
              style={{ width: "40px", height: "40px" }}
              alt="User Level"
            />
            &nbsp;
            <h6
              className="d-inline mt-3 ps-3"
              style={{ color: "black", fontWeight: 700 }}
            >
              {levelName}
            </h6>
          </div>
          <div className="float-end d-flex align-items-center justify-content-center">
            <div className="mt-1">
              <h6
                className="d-inline mt-5 pt-5"
                style={{ color: "black", fontWeight: 700 }}
              >
                Enter
              </h6>
              <img
                // src="{{asset('icons/No need to edit/forward.png')}}"
                src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
                style={{ width: "auto", height: "20px" }}
                alt="Forward"
              />
            </div>
          </div>
        </button>
      </div>

      {isWithdraw && (
        <div
          id="transactionModal"
          className="transaction-modal d-flex"
          style={{ display: "flex" }}
        >
          <div className="transaction-modal-content px-3">
            <div className="w-100 d-flex justify-content-end">
              <h2
                onClick={() => {
                  setIsWithdraw(false);
                }}
                id="transactionCloseBtn"
                style={{ cursor: "pointer" }}
                className="p-0 mr-2 font-weight-normal"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/OIP.jpg`}
                  style={{ width: "30px", height: "30px", cursor: "pointer" }}
                  alt=""
                />
              </h2>
            </div>
            <h5
              className="text-center"
              style={{
                color: "black",
                fontWeight: "600",
              }}
            >
              Enter Transaction Password
            </h5>
            <div className="d-flex justify-content-center w-100">
              <input
                id="transactionPassword"
                type="password"
                className="p-2 text-center"
                style={{ width: "80%" }}
                value={tranPassword}
                onChange={(e) => {
                  setTranPassword(e.target.value);
                }}
                placeholder="Enter Transaction Password"
              />
            </div>
            {errorTransactionPassword && (
              <small className=" text-danger ml-5" id="errorMyTransaction">
                Please Enter Your Transaction Password
              </small>
            )}
            <div className="d-flex justify-content-center">
              <button
                onClick={toWithdraw}
                id="transactionAcceptBtn"
                className="my-3 py-2 w-50 border-0"
                style={{ backgroundColor: "black", borderRadius: "3px" }}
              >
                <h6 className="text-white">Confirm</h6>
              </button>
            </div>
          </div>
        </div>
      )}

      {falseTranPass && (
        <div id="incorrectTransactionPassword" class="notification d-flex ">
          Your Transaction Password is incorrect
        </div>
      )}

      {isFirstImg && (
        <div
          id="imgModal"
          className="img-modal"
          style={{ borderRadius: "10px" }}
        >
          <div className="img-modal-content" style={{ borderRadius: "10px" }}>
            <span
              onClick={() => {
                hideFirstImg();
              }}
              id="imgCloseBtn"
              className="close"
            >
              &times;
            </span>
            <img
              src={`${process.env.PUBLIC_URL}/images/salary.jpg`}
              alt="Modal"
              style={{ width: "350px", borderRadius: "30px", height: "auto" }}
            />
          </div>
        </div>
      )}

      {/* start */}

      {isFirstTimeModal && (
        <div id="myModal" className=" modal">
          <div
            className="modal-content"
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <div
              className="image-container"
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/firstTime.jpg`}
                alt="Image with Orange Background"
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <button
                className="action-button"
                onClick={() => {
                  hideFirstTimeModal();
                  firstGiftFun();
                }} // Replace 1 with dynamic user ID if needed
                style={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  fontSize: "16px",
                  color: "white",
                  backgroundColor: "#ffa23c",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Claim
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
