import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import bcrypt from "bcryptjs";
import { useNavigate } from "react-router";
import { faArrowRight, faL } from "@fortawesome/free-solid-svg-icons";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import axios from "axios";
import { useFormState } from "react-hook-form";
// Import context or user data management

const WithdrawPage = () => {
  const navigate = useNavigate();
  const myUserData = JSON.parse(getUser());
  const token = getToken();
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const [user, setUser] = useState({
    wallet_balance: 0, // Example value, replace with actual data fetching
    todayPrice: 0,
    todayTeam: 0,
    totalPrice: 0,
    totalTeam: 0,
    withdrawal_fees: 0,
    withdrawLimit: 0,
    minimumWithdrawAmount: 0,
    maximumWithdrawAmount: 0,
    orderNumberByDay: 0,
    withdrawNumber: 0,
    withdrawFee: 0,
  });
  const [isBlocked, setIsBlocked] = useState(false);
  const [pendingData, setPendingData] = useState("pending");
  const [pass, setPass] = useState("");
  const [withdrawCount, setWithdrawCount] = useState(0);
  const [withdrawData, setWithdrawData] = useState([]);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [transactionPass, setTransactionPass] = useState("");
  const [notification, setNotification] = useState("");
  const [falseTranPass, setFalseTranPass] = useState(false);
  const [errorBlank, setErrorBlank] = useState(false);
  const [minimumError, setminimumError] = useState(false);
  const [maximumError, setMaximumError] = useState(false);
  const [method, setMethod] = useState("Crypto");
  const [isToday, setIsToday] = useState(true);
  const [isTotal, setIsTotal] = useState(false);
  const [insufficientError, setInsufficientError] = useState(false);
  function checkPassword(plainPassword, hashedPassword) {
    return bcrypt.compareSync(plainPassword, hashedPassword);
  }
  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        `https://admintesting.amzscoutmembers.com/api/withdraw`,
        {
          id: myUserData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response.data);
      if (response.data.status == "true") {
        console.log(response.data);
        if (response.data.pendingWithdraw !== null) {
          setPendingData(response.data.pendingWithdraw);
        }
        setUser(response.data.userData);
        setPass(response.data.pass);
        setWithdrawCount(response.data.withdrawCount);
        setWithdrawData(response.data.withdrawData);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  const postFetch = async (address, amount) => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        `https://admintesting.amzscoutmembers.com/api/postWithdraw`,
        {
          id: myUserData.id,
          method: "Crypto",
          address: address,
          amount: amount,
          fees: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status == "true") {
        setWithdrawSuccess(true);
        setTimeout(() => {
          setWithdrawSuccess(false);
        }, 2000);
        if (response.data.pendingWithdraw !== null) {
          setPendingData(response.data.pendingWithdraw);
        }
        setUser(response.data.userData);
        setPass(response.data.pass);
        setWithdrawCount(response.data.withdrawCount);
        setWithdrawData(response.data.withdrawData);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // For 12-hour format with AM/PM
    };
    return date.toLocaleString("en-US", options);
  };

  const [address, setAddress] = useState(
    localStorage.getItem("woopraLocation") || ""
  );

  const handleConfirmClick = () => {
    if (pendingData !== "pending") {
      setIsBlocked(true);
      setTimeout(() => {
        setIsBlocked(false);
      }, 1234);
      return;
    }

    if (+withdrawAmount < 0) {
      return;
    }
    if (transactionPass == "" || withdrawAmount == "" || address == "") {
      setErrorBlank(true);
      setTimeout(() => {
        setErrorBlank(false);
      }, 800);
      return;
    }

    if (+withdrawAmount > +user.wallet_balance) {
      setInsufficientError(true);
      setTimeout(() => {
        setInsufficientError(false);
      }, 1000);
      return;
    }

    if (+withdrawAmount < +user.minimumWithdrawAmount) {
      setminimumError(true);
      setTimeout(() => {
        setminimumError(false);
      }, 2000);
      return;
    }

    if (+withdrawAmount > +user.maximumWithdrawAmount) {
      setMaximumError(true);
      setTimeout(() => {
        setMaximumError(false);
      }, 2000);
      return;
    }

    if (checkPassword(transactionPass, pass)) {
      postFetch(address, withdrawAmount);
    } else {
      setFalseTranPass(true);
      setTimeout(() => {
        setFalseTranPass(false);
      }, 1500);
    }
  };

  const todayClicked = () => {
    setIsTotal(false);
    setIsToday(true);
  };
  const totalClicked = () => {
    setIsToday(false);
    setIsTotal(true);
  };
  useEffect(() => {
    fetchData();
    if (sessionStorage.getItem("pendingExisted")) {
      setNotification("Your withdrawal request is still under review.");
      setTimeout(() => setNotification(""), 2000);
    }
  }, []);

  return (
    <div className="loginBody">
      <style>
        {`
    .notification {
      opacity: 0.6;
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #333;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      z-index: 1000;
    }
    .firstTime-modal {
      display: block;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgba(0,0,0,0.4);
    }
    .firstTime-modal-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 20px;
      border: 1px solid #888;
      width: 80%;
      max-width: 500px;
      text-align: center;
      position: relative;
    }
    .firstTime-close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .firstTime-close:hover,
    .firstTime-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
    .firstTime-confirm-btn {
      background-color: black;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 3px;
      cursor: pointer;
      margin-top: 20px;
    }
    .firstTime-confirm-btn:hover {
      background-color: #333;
    }
  `}
      </style>

      <div
        method="POST"
        // action="/user/postWithdraw"
        className="loginContainer"
      >
        <div
          className="d-flex py-2 sticky-header position-fixed top-0"
          style={{ top: 0, backgroundColor: "black" }}
        >
          <div id="backBtn" style={{ color: "white", cursor: "pointer" }}>
            <div className="ms-2 ">
              <img
                onClick={() => {
                  navigate("/");
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{ width: "21px", height: "21px" }}
                alt="Back"
              />
            </div>
          </div>
          <h5
            className="text-white font-weight-bold text-center mt-1 col-11"
            style={{ fontSize: ".9rem" }}
          >
            Withdraw
          </h5>
        </div>

        <br />
        <br />
        <div className="px-3 py-1">
          <div className="mt-3 mx-md-3 mx-1 homeBigCardContainer">
            <div
              className="py-3 px-md-3 px-2 homeBigCard"
              style={{
                width: "100%",
                backgroundColor: "black",
                borderRadius: "10px",
              }}
            >
              <h6 style={{ fontWeight: 600 }} className="my-0 py-0 text-white">
                Wallet Account
              </h6>
              <div className="d-flex text-white">
                <h3
                  style={{ fontStyle: "italic", fontWeight: 700 }}
                  className="mt-2"
                >
                  {user.wallet_balance}
                </h3>
                <div className="mt-3 pt-1" style={{ fontSize: ".8rem" }}>
                  USD
                </div>
              </div>

              <div className="mt-2 homeBtnContainer">
                <button
                  onClick={() => {
                    todayClicked();
                  }}
                  id="firstToday"
                  className="px-1"
                  style={{
                    backgroundColor: isToday ? "rgb(241, 187, 14)" : "black",
                    color: isToday ? "black" : "white",
                    borderRadius: "3px",
                    paddingTop: "2px",
                    border: isToday ? "none" : "white solid .4px",
                    paddingBottom: "2px",

                    fontSize: ".8rem",
                    outline: "none",
                  }}
                >
                  Today's Earnings
                </button>
                <button
                  id="firstTotal"
                  onClick={() => {
                    totalClicked();
                  }}
                  className="ms-3 px-1  "
                  style={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    fontSize: ".8rem",
                    outline: "none",
                    border: isTotal ? "none" : "white solid .4px",
                    backgroundColor: isTotal ? "rgb(241, 187, 14)" : "black",
                    color: isTotal ? "black" : "white",
                    borderRadius: "3px",
                  }}
                >
                  Total Earnings
                </button>
                {/* <button
        id="secondTotal"
        className="px-1  text-white"
        style={{

          paddingTop: "2px",
          paddingBottom: "2px",
          fontSize: ".8rem",
          outline: "none",
          backgroundColor: "black",
          borderRadius: "3px",
          display: "none",
        }}
      >
        Todays Earnings
      </button>
      <button
        id="secondToday"
        className="ms-3 px-1"
        style={{
          display: "none",
          backgroundColor: "rgb(241, 187, 14)",
          borderRadius: "3px",
          paddingTop: "2px",
          paddingBottom: "2px",
          border: 0,
          fontSize: ".8rem",
          outline: "none",
        }}
      >
        Total Earnings
      </button> */}
              </div>

              <div className="homeCasesContainer d-flex mt-md-2 mt-2">
                <div
                  id="firstOrder"
                  className="revenueContainer me-3 pe-2"
                  style={{
                    display: "inline",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Order Revenue
                  </div>
                  <div className="text-white">
                    {isToday
                      ? user.todayPrice.toFixed(3)
                      : user.totalPrice.toFixed(3)}
                  </div>
                </div>
                <div
                  id="firstTeam"
                  className="teamContainer me-3 pe-2"
                  style={{
                    display: "inline",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Team
                  </div>
                  <div className="text-white">
                    {" "}
                    {isToday
                      ? user.todayTeam.toFixed(3)
                      : user.totalTeam.toFixed(3)}{" "}
                  </div>
                </div>
                <div
                  id="firstEarning"
                  style={{ display: "inline" }}
                  className="totalContainer me-3 pe-2"
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Total Earnings
                  </div>
                  <div className="text-white">
                    {" "}
                    {isToday
                      ? (user.todayPrice + user.todayTeam).toFixed(3)
                      : (user.totalPrice + user.totalTeam).toFixed(3)}{" "}
                  </div>
                </div>
                <div
                  id="secondOrder"
                  className="revenueContainer me-3 pe-2"
                  style={{
                    display: "none",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Order Revenue
                  </div>
                  <div className="text-white">345.2</div>
                </div>
                <div
                  id="secondTeam"
                  className="teamContainer me-3 pe-2"
                  style={{
                    display: "none",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Team
                  </div>
                  <div className="text-white">345.23</div>
                </div>
                <div
                  id="secondEarning"
                  className="totalContainer me-3 pe-2"
                  style={{ display: "none" }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Total Earnings
                  </div>
                  <div className="text-white">342.22</div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-md-3 mt-1 px-md-3 px-0 me-md-0 me-2 pt-3 pb-2">
            <h5
              className="ms-3"
              style={{ fontSize: ".9rem", color: "black", fontWeight: 600 }}
            >
              Withdrawal Method
            </h5>
            <div className="bg-white px-0 ms-3 py-2 col-12 row loginInput d-flex">
              <input
                id="method"
                name="method"
                type="text"
                disabled
                className="ps-4 col-6 inputLogin border-0"
                placeholder="Withdrawal Method"
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              />
              <input
                id="address"
                name="address"
                type="text"
                disabled
                className="col-4 inputLogin border-0"
                dir="rtl"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <div className="col-2 d-flex flex-column justify-content-center align-items-end">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  className="me-2 mt-1"
                  alt="Forward"
                  onClick={() => {
                    navigate("/ways");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-1 px-md-3 px-0 me-md-0 me-2 pt-3 pb-2">
            <h5
              className="ms-3"
              style={{ fontSize: ".9rem", color: "black", fontWeight: 600 }}
            >
              Withdraw Amount
            </h5>
            <div className="bg-white px-0 ms-3 py-2 col-12 row loginInput d-flex">
              <input
                name="amount"
                type="number"
                id="withdrawlAmountInput"
                className="bg-white ps-4 col-8 inputLogin border-0"
                placeholder="Withdrawal Amount"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
              />
              <div className="col-4 d-flex flex-column justify-content-center align-items-end">
                <div
                  id="allAmount"
                  className="px-4 py-1"
                  style={{
                    cursor: "pointer",
                    borderRadius: "5px",
                    fontWeight: 600,
                    fontSize: ".8rem",
                    backgroundColor: "rgb(241, 187, 14)",
                  }}
                  onClick={() => setWithdrawAmount(user.wallet_balance)}
                >
                  ALL
                </div>
              </div>
            </div>
            {/* Error messages */}
          </div>

          <div className="mt-1 px-md-3 px-0 me-md-0 me-2 pt-3 pb-2">
            <h5
              className="ms-3"
              style={{ color: "black", fontWeight: 600, fontSize: ".9rem" }}
            >
              Transaction Password
            </h5>
            <div className="py-2 px-0 ms-3 col-12 loginInput bg-white">
              <input
                name="password"
                type="password"
                id="transactionPass"
                className="ps-2 w-100 inputLogin border-0"
                placeholder="Transaction Password"
                value={transactionPass}
                onChange={(e) => setTransactionPass(e.target.value)}
              />
            </div>
            {/* Error messages */}
          </div>

          <div id="notification" className="notification">
            {notification}
          </div>

          <button
            type="button"
            id="confirmBtn"
            className="mt-3 py-3 border-0 registerBtn font-weight-bold w-100"
            onClick={handleConfirmClick}
          >
            <h4
              className="mt-1 font-weight-normal"
              style={{ fontSize: ".9rem" }}
            >
              Confirmation of Withdraw
            </h4>
          </button>
          <button id="realConfirmBtn" hidden type="submit"></button>

          <div className="mt-3 d-flex justify-content-center">
            <small>Withdrawals Will Be Processed Within 1 Hour</small>
          </div>
        </div>

        <div
          className="my-4 py-3 mx-2 mb-5 bg-white"
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "6px",
          }}
        >
          <div className="float-start">
            {/* <img src="/icons/No need to edit/book.png" style={{ width: '30px', height: '30px', borderRadius: '6px' }} alt="" /> */}
            <div
              className="d-inline ms-3"
              style={{ fontWeight: "690", color: "black" }}
            >
              Withdraw History
            </div>
          </div>
          <div
            id="toWHistory"
            className="float-end"
            onClick={() => {
              navigate("/whistory");
            }}
            style={{ cursor: "pointer", color: "rgb(243, 187, 6)" }}
          >
            <div className="d-inline ms-3" style={{ fontWeight: "690" }}>
              More
              <FontAwesomeIcon className=" ms-2" icon={faArrowRight} />
            </div>
            <i className="fa-solid fa-arrow-right"></i>
            <input type="hidden" id="count" value={withdrawCount} />
            <input
              type="hidden"
              value={user.withdrawLimit}
              id="limit"
              name="limit"
            />
            <input
              type="hidden"
              value={user.withdrawNumber}
              id="number"
              name="number"
            />
            <input
              type="hidden"
              id="fees"
              value={user.withdrawFee}
              name="fees"
            />
            <input type="hidden" value={user.wallet_balance} id="myBalance" />
          </div>
          <br />
          {withdrawData.map((each) => (
            <div
              key={each.id}
              className=" text-decoration-none mx-md-4 mx-2 col-11 row  eachNoti my-2 px-0"
              style={{
                backgroundColor: "#f8f7f7",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "6px",
              }}
            >
              <div className="d-flex flex-column justify-content-center overflow-hidden col-md-1 col-2 px-0">
                <div className="d-flex flex-column justify-content-center col-12 col-md-8 mx-0 px-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Change them into Red/Deposit.png`}
                    style={{ width: "100%", height: "50px" }}
                    alt=""
                  />
                </div>
              </div>

              <div className="px-0 offset-md-0 offset-1 col-9 messagesText pt-3 d-flex flex-column justify-content-center">
                <div>
                  <div
                    className="eachMessageText text-decoration-none"
                    style={{ fontWeight: "660", color: "black" }}
                  >
                    Withdrawal Amount Proceeds {each.amount}
                  </div>
                  <small
                    className="text-decoration-none"
                    style={{ color: "black" }}
                  >
                    {formatTimestamp(each.created_at)}
                  </small>
                </div>
              </div>
              <div className="col-md-4 col-1 d-flex flex-column justify-content-center align-items-end"></div>
            </div>
          ))}

          <br />
          <br />
          <br />
          {falseTranPass && (
            <div id="incorrectTransactionPassword" class="notification d-flex ">
              Your Transaction Password is incorrect
            </div>
          )}
          {isBlocked && (
            <div id="incorrectTransactionPassword" class="notification d-flex ">
              Your Withdrawal request is still under review.
            </div>
          )}
          {insufficientError && (
            <div id="incorrectTransactionPassword" class="notification d-flex ">
              Your Request Amount is insufficient.
            </div>
          )}
          {errorBlank && (
            <div id="incorrectTransactionPassword" class="notification d-flex ">
              Please Fill All The Forms
            </div>
          )}
          {minimumError && (
            <div id="incorrectTransactionPassword" class="notification d-flex ">
              Your Request Amount must be greater than{" "}
              {user.minimumWithdrawAmount}
            </div>
          )}
          {maximumError && (
            <div id="incorrectTransactionPassword" class="notification d-flex ">
              Your Request Amount must be less than {user.maximumWithdrawAmount}
            </div>
          )}
          {withdrawSuccess && (
            <div id="incorrectTransactionPassword" class="notification d-flex ">
              Posted Withdraw Successfully
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WithdrawPage;
