import { useState } from "react";
import { useNavigate } from "react-router";

const WaysPage = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState(
    localStorage.getItem("woopraLocation")
  );
  return (
    <div
      className="loginBody   position-relative   "
      style={{ overflow: "initial !important", backgroundColor: "#f9f9f9" }}
    >
      <div
        className="loginContainer  position-relative top-0"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div style={{}} className="d-flex bg-black py-2  top-0">
          <div
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            className="back-btn"
          >
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate("/withdraw");
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                className="back-img"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                alt="back"
              />
            </div>
          </div>
          <h5
            style={{ fontSize: "1rem" }}
            className="text-white font-weight-bold text-center col-11 mt-1 title"
          >
            Withdrawal Methods
          </h5>
        </div>

        <div
          style={{
            marginTop: "1rem",
            padding: "0.25rem 0.75rem",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              marginTop: "1rem",
              marginBottom: "3rem",
              backgroundColor: "white",
            }}
          >
            <div
              id="leftBar"
              className=" float-start"
              style={{ display: "flex" }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/wallet.png`}
                alt=""
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
              />
              <div
                id="addressWrapper"
                style={{
                  display: address == null && "none",
                  marginLeft: "0.75rem",
                }}
              >
                Digital Currency Wallet Address
                <h6
                  id="address"
                  className=" text-black"
                  style={{
                    marginLeft: "0.25rem",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  - {address}
                </h6>
              </div>
            </div>
            <div
              className=" float-end"
              id="rightBar"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {address == null ? (
                <img
                  id="toUsdt"
                  onClick={() => {
                    navigate("/usdt");
                  }}
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/plusIcon.png`}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "0.5rem",
                    marginTop: "0.25rem",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <img
                  id="toUsdtEdit"
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/tradeedit.png`}
                  onClick={() => {
                    navigate("/usdt");
                  }}
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "0.5rem",
                    marginTop: "0.25rem",
                  }}
                />
              )}
            </div>
          </div>
          <br />
        </div>

        <div className="" style={{ height: "80vh" }}></div>
      </div>
    </div>
  );
};
export default WaysPage;
