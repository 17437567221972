import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getToken } from "../utils/localStorage";
import context from "react-bootstrap/esm/AccordionContext";

const ClausePage = () => {
  const navigate = useNavigate();
  const token = getToken();
  const [data, setData] = useState({
    common_tasks: "",
    advanced_tasks: "",
    properties_and_characteristics: "",
  });
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://admintesting.amzscoutmembers.com/api/clause"
      );
      console.log(response.data.context);
      if (response.data.status == "true") {
        setData(response.data.context);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div
      className="loginBody   position-relative   "
      style={{ overflow: "initial !important", backgroundColor: "#f9f9f9" }}
    >
      <div
        className="loginContainer  position-relative top-0"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div
          style={{}}
          className="d-flex py-2 sticky-header position-fixed top-0"
        >
          <div
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            className="back-btn"
          >
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                className="back-img"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                alt="back"
              />
            </div>
          </div>
          <h5
            style={{ fontSize: "1rem" }}
            className="text-white font-weight-bold text-center col-11 mt-1 title"
          >
            Illustrate
          </h5>
        </div>
        <br />
        <br />
        <br />
        <div style={{ marginTop: "1rem", padding: "1rem" }}>
          <div className="content">
            <h1 style={{ textAlign: "center", color: "rgb(0, 58, 140)" }}>
              Task type description
            </h1>
            <p>
              <br />
            </p>
            <hr />
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <h2 style={{ color: "rgb(9, 109, 217)" }}>Common tasks</h2>
            <div dangerouslySetInnerHTML={{ __html: data.common_tasks }}></div>
            <hr />
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <h2 style={{ color: "rgb(9, 109, 217)" }}>
              <strong>Advanced tasks</strong>
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: data.advanced_tasks }}
            ></div>
            <hr />
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <h2 style={{ color: "rgb(9, 109, 217)" }}>
              <strong>Properties and characteristics</strong>
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.properties_and_characteristics,
              }}
            ></div>
            <hr />
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClausePage;
