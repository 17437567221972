import { faL } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import axios from "axios";

const MyTeamPage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isToday, setIsToday] = useState(true);
  const [isTotal, setIsTotal] = useState(false);
  const [isTeamModalCopy, setIsTeamCopy] = useState(false);
  const myUserData = JSON.parse(getUser());
  const token = getToken();
  const todayClicked = () => {
    setIsTotal(false);
    setIsToday(true);
  };
  const totalClicked = () => {
    setIsToday(false);
    setIsTotal(true);
  };
  const orderClickBtn = () => {
    const textToCopy = myUserData.invite_code;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        setIsCopied(false);
        console.error("Could not copy text: ", err);
      });
  };
  const [userData, setUserData] = useState({
    name: "",
    levelImg: "",
    reputation_score: 100,
    invite_code: "INV12345",
    todayPrice: 0,
    todayTeam: 0,
    totalPrice: 0,
    totalTeam: 0,
  });

  const [lowLevelUsers, setLowLevelUsers] = useState([]);

  const handleToggleView = () => {
    setShowSecond(!showSecond);
  };

  // const handleModalOpen = () => {
  //   setShowModal(true);
  // };

  const handleModalClose = () => {
    // setShowModal(false);
  };

  const handleCopyInviteCode = () => {
    const inviteCode = document.getElementById("hiddenCode").value;
    navigator.clipboard.writeText(inviteCode);

    // const notification = document.getElementById("copyNotification");
    // console.log(notification);
    // document.getElementById("copyNotification").style.display = "flex";
    setTimeout(() => {
      // document.getElementById("copyNotification").style.display = "none";
    }, 2000);
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admintesting.amzscoutmembers.com/api/myTeam",
        {
          id: myUserData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      if (response.data.status == "true") {
        setUserData(response.data.userData);
        // setTodayLowLevelUsers(response.data.todayLowLevelUsers);
        setLowLevelUsers(response.data.lowLevelUsers);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="loginBody">
      <style>
        {` 
        .loginBody {
    background-color: #f0f0f0;
}

.loginContainer {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.profilesHeader {

}

.profileTextContainer {
    margin-top: 0;
}

.homeBigCardContainer {
    margin-top: 20px;
}

.homeBigCard {
    border-radius: 10px;
}

.homeBtnContainer button {
    margin-right: 10px;
}

.homeCasesContainer {
    margin-top: 10px;
}

.myTeamList {
    display: flex;
    align-items: center;

    padding: 10px 0;
}

.inviteFriends {
  
}

.inviteFriendsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background: #333;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    color: white;
}

   .notification {
    opacity:0.6;
  display: flex;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #333;
        color: #fff;
        padding: 15px;
        border-radius: 5px;
        z-index: 1000;
    }

        
        `}
      </style>
      <div className="loginContainer">
        <div
          className="d-flex py-2 position-fixed top-0 sticky-header"
          style={{ top: 0, zIndex: 4, backgroundColor: "black" }}
        >
          <div
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <div className="ms-2">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{ width: "21px", height: "21px" }}
                alt="Back"
              />
            </div>
          </div>
          <h5
            className="text-white font-weight-bold text-center mt-1 col-11"
            style={{ fontSize: ".9rem" }}
          >
            My Team
          </h5>
        </div>
        <br />
        <br />
        <div className="mt-3 px-3  py-3">
          <div className="profilesHeader ">
            <div className="ms-2 d-flex">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/Profile logo.png`}
                style={{ width: "65px", height: "65px" }}
                alt="Profile"
              />
              <div>
                <div className="d-block ms-2 profileTextContainer">
                  <div className="m-0 p-0 d-flex">
                    <h5
                      style={{ color: "black", fontWeight: 650 }}
                      className="m-0 p-0"
                    >
                      {userData.name}
                    </h5>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`}
                      style={{ width: "24px", height: "24px" }}
                      className="ms-2"
                      alt="Level"
                    />
                  </div>
                </div>
                <div className="w-100 ms-2">
                  <h6 className="w-100 d-flex text-secondary">
                    Credit Score:
                    <div
                      className="mt-2 ms-2"
                      style={{
                        backgroundColor: "rgb(241, 187, 14)",
                        height: "1px",
                        width: "100px",
                        borderRadius: "5px",
                        padding: "2px",
                      }}
                    ></div>
                    <div style={{ color: "black", fontWeight: 700 }}>
                      {userData.reputation_score}%
                    </div>
                  </h6>
                </div>
                <div className="w-100 ms-2">
                  <h6
                    className="w-100 m-0 p-0 d-flex text-secondary"
                    style={{ fontWeight: 560 }}
                  >
                    Invite Code:
                    <div
                      style={{ fontWeight: 700, color: "black" }}
                      className="ms-2"
                    >
                      {userData.invite_code}
                    </div>
                    <div
                      className="ms-2 px-1 py-1"
                      id="copyBtn"
                      style={{
                        cursor: "pointer",
                        fontSize: ".8rem",
                        fontWeight: 600,
                        color: "black",
                        backgroundColor: "rgb(241, 187, 14)",
                        borderRadius: "9px",
                      }}
                      onClick={() => {
                        orderClickBtn();
                      }}
                    >
                      Copy
                    </div>
                    <input
                      type="hidden"
                      id="hiddenCode"
                      value={userData.invite_code}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* Big card */}
          <div className="mt-3 mx-3 homeBigCardContainer position-relative">
            <div
              className="py-4 px-4 homeBigCard"
              style={{
                width: "100%",
                backgroundColor: "black",
                borderRadius: "10px",
              }}
            >
              <h6 className="text-white">Wallet Account</h6>
              <div className="d-flex text-white">
                <h3 style={{ fontStyle: "italic", fontWeight: 700 }}>
                  {userData.wallet_balance}
                  {/* {Number(userData.wallet_balance).toFixed(2)} */}
                </h3>
                <div className="mt-md-2 mt-1 ml-1">USD</div>
              </div>
              <div className="mt-2 homeBtnContainer">
                <button
                  onClick={() => {
                    todayClicked();
                  }}
                  id="firstToday"
                  className="px-1"
                  style={{
                    backgroundColor: isToday ? "rgb(241, 187, 14)" : "black",
                    color: isToday ? "black" : "white",
                    borderRadius: "3px",
                    paddingTop: "2px",
                    border: isToday ? "none" : "white solid .4px",
                    paddingBottom: "2px",

                    fontSize: ".8rem",
                    outline: "none",
                  }}
                >
                  Today's Earnings
                </button>
                <button
                  id="firstTotal"
                  onClick={() => {
                    totalClicked();
                  }}
                  className="ms-3 px-1  "
                  style={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    fontSize: ".8rem",
                    outline: "none",
                    border: isTotal ? "none" : "white solid .4px",
                    backgroundColor: isTotal ? "rgb(241, 187, 14)" : "black",
                    color: isTotal ? "black" : "white",
                    borderRadius: "3px",
                  }}
                >
                  Total Earnings
                </button>
                {/* <button
              id="secondTotal"
              className="px-1  text-white"
              style={{

                paddingTop: "2px",
                paddingBottom: "2px",
                fontSize: ".8rem",
                outline: "none",
                backgroundColor: "black",
                borderRadius: "3px",
                display: "none",
              }}
            >
              Todays Earnings
            </button>
            <button
              id="secondToday"
              className="ms-3 px-1"
              style={{
                display: "none",
                backgroundColor: "rgb(241, 187, 14)",
                borderRadius: "3px",
                paddingTop: "2px",
                paddingBottom: "2px",
                border: 0,
                fontSize: ".8rem",
                outline: "none",
              }}
            >
              Total Earnings
            </button> */}
              </div>
              <div className="homeCasesContainer d-flex mt-md-2 mt-2">
                <div
                  id="firstOrder"
                  className="revenueContainer me-3 pe-2"
                  style={{
                    display: "inline",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Order Revenue
                  </div>
                  <div className="text-white">
                    {isToday
                      ? userData.todayPrice.toFixed(3)
                      : userData.totalPrice.toFixed(3)}
                  </div>
                </div>
                <div
                  id="firstTeam"
                  className="teamContainer me-3 pe-2"
                  style={{
                    display: "inline",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Team
                  </div>
                  <div className="text-white">
                    {isToday
                      ? userData.todayTeam.toFixed(3)
                      : userData.totalTeam.toFixed(3)}
                  </div>
                </div>
                <div
                  id="firstEarning"
                  style={{ display: "inline" }}
                  className="totalContainer me-3 pe-2"
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Total Earnings
                  </div>
                  <div className="text-white">
                    {isToday
                      ? (userData.todayPrice + userData.todayTeam).toFixed(3)
                      : (userData.totalPrice + userData.totalTeam).toFixed(3)}
                  </div>
                </div>
                <div
                  id="secondOrder"
                  className="revenueContainer me-3 pe-2"
                  style={{
                    display: "none",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Order Revenue
                  </div>
                  <div className="text-white">345.2</div>
                </div>
                <div
                  id="secondTeam"
                  className="teamContainer me-3 pe-2"
                  style={{
                    display: "none",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Team
                  </div>
                  <div className="text-white">345.23</div>
                </div>
                <div
                  id="secondEarning"
                  className="totalContainer me-3 pe-2"
                  style={{ display: "none" }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    Total Earnings
                  </div>
                  <div className="text-white">342.22</div>
                </div>
              </div>
              <button
                className=" position-absolute mr-4 mt-3 "
                id="teamDetails"
                onClick={() => {
                  navigate("/commisionStatement");
                }}
                style={{
                  outline: "none",
                  cursor: "pointer",
                  top: "0",
                  right: "0",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "rgb(241, 187, 14)",
                  borderRadius: "9px",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/descImg.png`}
                  style={{ width: "35px", height: "35px" }}
                  alt=""
                />
                <span style={{ color: "black", fontWeight: "600" }}>
                  Team Description
                </span>
              </button>
            </div>
          </div>

          {/* List section */}
          <div className="mt-4 px-3 py-2  homeList">
            <div className=" ">
              <h6 className=" float-start homeListTitle text-black">
                Frends I invited : {lowLevelUsers.length}
              </h6>
              <div
                onClick={() => {
                  navigate("/teamRevenue");
                }}
                className="  float-end text-warning"
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                More <FaArrowRight />
              </div>
            </div>
            <br />
            <br />
            <div className="homeListContainer">
              {lowLevelUsers.length > 0 ? (
                lowLevelUsers.map((user) => (
                  <div className="myTeamList" key={user.id}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/No need to edit/Profile logo.png`}
                      style={{ width: "50px", height: "50px" }}
                      alt="Profile"
                    />
                    <div className="d-flex justify-content-between w-100 ms-2 mt-1">
                      <div className="ms-1 d-block text-start">
                        <div className="d-block" style={{ fontWeight: 500 }}>
                          {user.name}
                        </div>
                        <div
                          className="text-secondary"
                          style={{ fontSize: ".8rem" }}
                        >
                          Invite Code: {user.invite_code}
                        </div>
                      </div>
                      <div
                        className="text-end me-2"
                        style={{ color: "black", fontWeight: 500 }}
                      >
                        {user.total_orders}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No users found</div>
              )}
            </div>
          </div>
        </div>

        {/* Modal for Invite Friends */}
        {showModal && (
          <div className="modalBackdrop" onClick={handleModalClose}>
            <div className="modalContent" onClick={(e) => e.stopPropagation()}>
              <h5 className="text-white">Invite Friends</h5>
              <textarea
                className="form-control mt-2"
                value={userData.invite_code}
                readOnly
              />
              <button
                className="btn btn-primary mt-2"
                onClick={() => {
                  console.log("hello");
                }}
              >
                Copy Invitation Link
              </button>
              <button
                className="btn btn-secondary mt-2"
                onClick={handleModalClose}
              >
                Close
              </button>
              <div id="copyNotification" className="notification">
                Copied to clipboard!
              </div>
            </div>
          </div>
        )}

        {/* More link */}
        <div className="inviteFriends position-relative">
          <img
            src={`${process.env.PUBLIC_URL}/images/No need to edit/bigMyteam.png`}
            className="w-100"
            alt=""
          />
          <div className="position-absolute pt-3" style={{ top: 0 }}>
            <h3
              className="text-white text-center"
              style={{ fontWeight: 300, fontFamily: "Arial, sans-serif" }}
            >
              Invite Friends And Get Commissions
            </h3>
            <h5
              className="text-white mx-5 copyText"
              style={{ fontWeight: 300, fontFamily: "Arial, sans-serif" }}
            >
              During the term of use, if your friend signs up for your
              membership through a link or promo code you share, your friend
              will become your associate agent and you will receive a portion of
              their commission. You can earn up to 3 levels of team commission.
            </h5>
            <h5
              style={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                margin: "1rem 0",
              }}
            >
              Invitation Code: {userData.invite_code}
            </h5>
            <div className="mt-4 mx-5">
              <button
                id="teamOpenModalBtn"
                className="outline-none w-100 py-3 text-center"
                style={{
                  cursor: "pointer",
                  borderRadius: "14px",
                  color: "white",
                  border: "none",
                  backgroundColor: "rgb(241, 187, 14)",
                  fontFamily: "Arial, sans-serif",
                }}
                onClick={() => {
                  setIsTeamCopy(true);
                }}
              >
                Copy Invitation Link
              </button>
            </div>

            {isCopied && (
              <div id="copyNotification" className="notification">
                You have successfully copied the invite code.
              </div>
            )}
            {isTeamModalCopy && (
              <div id="teamModal" class="team-modal d-flex">
                <div class="team-modal-content px-3">
                  <div class="w-100 d-flex justify-content-end">
                    <h2 id="teamCloseBtn" class="p-0 mr-2 font-weight-normal">
                      <img
                        onClick={() => {
                          setIsTeamCopy(false);
                        }}
                        src={`${process.env.PUBLIC_URL}/images/No need to edit/OIP.jpg`}
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                        alt=""
                      />
                    </h2>
                  </div>

                  <div class="d-flex justify-content-center">
                    <button
                      id="teamAcceptBtn"
                      class="my-3 py-2 w-50 border-0"
                      style={{ backgroundColor: "black", borderRadius: "3px" }}
                    >
                      <h6 class="text-white">
                        Generate Promotional Texts and Links
                      </h6>
                    </button>
                  </div>
                  <div class="d-flex justify-content-center">
                    <button
                      id="teamAcceptBtn"
                      class="my-3 py-2 w-50 border-0"
                      style={{ backgroundColor: "black", borderRadius: "3px" }}
                    >
                      <h6 class="text-white">Generate Qr code Poster</h6>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTeamPage;
