import React, { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router";
import { getUser } from "../utils/localStorage";

const InformationPage = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(getUser());

  useEffect(() => {
    const transaction = document.getElementById("transactionUpdate");
    transaction.addEventListener("click", () => {
      window.location.href = "updateTransactionPassword";
    });

    const updatePassword = document.getElementById("passwordUpdate");
    updatePassword.addEventListener("click", () => {
      window.location.href = "updatePassword";
    });

    const backBtn = document.getElementById("backBtn");
    backBtn.addEventListener("click", () => {
      window.location.href = "/profilesPage";
    });

    const profile = document.getElementById("profile");
    const input = document.getElementById("addImg");

    profile.addEventListener("click", () => {
      input.click();
    });

    const email = document.getElementById("email");
    const fakeConfirm = document.getElementById("fakeConfirm");
    const realConfirm = document.getElementById("realConfirm");
    fakeConfirm.addEventListener("click", () => {
      if (email.value === "") {
        return;
      } else {
        realConfirm.click();
      }
    });
  }, []);

  return (
    <div>
      <form
        method="POST"
        action="/updateEmail"
        className="loginContainer"
        style={{ overflow: "initial !important", backgroundColor: "white" }}
      >
        <div
          className="d-flex py-2 sticky-header position-fixed top-0"
          style={{ top: 0, backgroundColor: "black" }}
        >
          <div
            id="backBtn"
            className=""
            style={{ color: "white", cursor: "pointer" }}
          >
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{ width: "25px", height: "25px" }}
                alt=""
              />
            </div>
          </div>
          <h5
            className="m-0 p-0 text-white font-weight-bold text-center col-11 mt-1"
            style={{ fontSize: ".9rem" }}
          >
            Modify Information
          </h5>
        </div>

        <br />
        <br />
        <div style={{ backgroundColor: "#f8f8f8" }}>
          <div className="d-flex justify-content-center">
            <div className="mt-3 py-3 px-3">
              <div className="van-uploader__input-wrapper">
                <div
                  data-v-ac10697e=""
                  className="van-image van-image--round"
                  style={{ width: "5rem", height: "5rem" }}
                >
                  <img
                    id="profile"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/Profile logo.png`}
                    className="van-image__img"
                    style={{
                      objectFit: "cover",
                      cursor: "pointer",
                      width: "80px",
                      height: "80px",
                    }}
                    alt=""
                  />
                </div>
                <input
                  id="addImg"
                  type="file"
                  className="van-uploader__input"
                  hidden
                  accept="image/*"
                />
              </div>
            </div>
          </div>

          <div
            className="mb-4 d-flex justify-content-center"
            style={{ backgroundColor: "#f8f8f8" }}
          >
            <div>
              Tag To Change <FaEdit className=" mb-1" />
            </div>
          </div>
          <br />
        </div>

        <div className="mt-4" style={{ backgroundColor: "#f8f8f8 !important" }}>
          <div className="ms-4 mt-2 float-start semibold">Username</div>
          <div className="me-5 mt-2 float-end semibold">{userData.name}</div>
        </div>
        <br />
        <br />
        <div className="" style={{ backgroundColor: "#f8f8f8 !important" }}>
          <div className="ms-4 mt-2 float-start semibold">Email</div>
          <input
            dir="rtl"
            type="text"
            name="email"
            id="email"
            className="col-5 float-end border-0 me-4"
            style={{ outline: "none" }}
            value={userData.email} // {Auth::user()->email}
            placeholder="Bind Email"
          />
        </div>
        <br />
        <br />
        <div className="mt-4" style={{ backgroundColor: "#f8f8f8 !important" }}>
          <div
            className="ms-4 mt-2 float-start semibold"
            style={{ textDecoration: "none", color: "black" }}
          >
            Update Password
          </div>
          <div className="me-5 mt-2 float-end semibold">
            <img
              id="passwordUpdate"
              src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
              style={{ width: "20px", height: "20px" }}
              alt=""
            />
          </div>
        </div>
        <br />
        <br />
        <div className="" style={{ backgroundColor: "#f8f8f8 !important" }}>
          <div className="ms-4 mt-2 float-start semibold">
            Update Transaction Password
          </div>
          <div className="mb-5 me-5 mt-2 float-end semibold">
            <img
              id="transactionUpdate"
              src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
              style={{
                textDecoration: "none",
                color: "black",
                width: "20px",
                height: "20px",
              }}
              alt=""
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <button
          className="mx-2 mx-md-5 my-5 py-3"
          style={{
            cursor: "pointer",
            width: "90%",
            boxShadow: "none",
            outline: "none",
            backgroundColor: "rgb(241, 187, 14)",
            borderRadius: "5px",
            border: "none",
          }}
          type="button"
          id="fakeConfirm"
        >
          Confirm
        </button>
        <button
          type="submit"
          id="realConfirm"
          hidden
          className="btn btn-outline-primary"
        >
          asdfaf
        </button>
      </form>

      <div className="" style={{ height: "20vh" }}></div>
    </div>
  );
};

export default InformationPage;
