import { useNavigate } from "react-router";

const MoreServicesPage = () => {
  const navigate = useNavigate();
  return (
    <div
      className="loginBody   position-relative   "
      style={{ overflow: "initial !important", backgroundColor: "#f9f9f9" }}
    >
      <div
        className="loginContainer  position-relative top-0"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div style={{}} className="d-flex py-2   bg-black top-0">
          <div
            onClick={() => {
              navigate(-1);
            }}
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            className="back-btn"
          >
            <div className="ms-3">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                className="back-img"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                alt="back"
              />
            </div>
          </div>
          <h5
            style={{ fontSize: "1rem" }}
            className="text-white font-weight-bold text-center col-11 mt-1 title"
          >
            More Services
          </h5>
        </div>

        <div
          className=" bg-white"
          style={{ margin: "1rem 0", padding: "1rem" }}
        >
          <img
            onClick={() => {
              navigate("/myTeam");
            }}
            src={`${process.env.PUBLIC_URL}/images/No need to edit/myTeam.png`}
            alt="My Team"
            style={{
              cursor: "pointer",
              width: "45px",
              height: "45px",
              marginLeft: "1rem",
            }}
            id="myTeam"
          />
          <div
            style={{
              marginTop: "0.25rem",
              marginLeft: "1rem",
              fontWeight: 500,
            }}
          >
            My Team
          </div>
        </div>

        <div className="" style={{ height: "60vh" }}></div>
      </div>
    </div>
  );
};
export default MoreServicesPage;
